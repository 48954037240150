import React, { useEffect, useState } from "react";
import { getFetch } from "../../functions/fetch/fetch.crud";
import Loader from "../../components/common/loader/Loader";
import { Icon } from "@iconify/react/dist/iconify.js";
import { CreateUserPopup } from "../../components/popup/CreateUserPopup";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";

const Institutes = () => {
  // const loggedUserInstitute = useSelector((state) => state.institute.value);
  const [loading, setLoading] = useState(true);
  const [institutes, setInstitutes] = useState([]);
  const [showPopup, setShowPopup] = useState("");
  const [instituteUsers, setInstituteUsers] = useState(null);
  const [currentInstituteId, setCurrentInstituteId] = useState(null);
  useEffect(() => {
    async function getInstitutes() {
      const response = await getFetch("institute/all");
      // console.log("response =", response);
      setInstitutes(response.institutes);
      setLoading(false);
      return;
    }
    if (loading) {
      getInstitutes();
      return;
    }
  }, [loading]);
  async function handleChangeUsersOfInstitute(e, institute) {
    const response = await getFetch(
      `user/institute-users/${institute._id}`,
      institute._id
    );
    if (response.error) {
      toast.error(response.message);
      return;
    }
    setCurrentInstituteId(institute._id);
    console.log("users =", response);
    setInstituteUsers(response.users);
    return;
  }

  async function handleAddUserUnderInstitute() {
    setShowPopup("createUser");
    return;
  }

  async function handleActivateInstitute(institute) {
    try {
      const response = await getFetch(
        `institute/dynamic/activate/${institute._id}`,
        institute._id
      );
      console.log("response =", response);
      if (response.error) {
        toast.error(response.error);
        return;
      } else {
        toast.success(response.message);
        return setTimeout(() => {
          // window.location.reload();
        }, 2500);
      }
    } catch (error) {
      console.log("error while activate institute.", error);
      return;
    }
  }

  return (
    <div className="p-5">
      {loading ? (
        <Loader />
      ) : (
        <div className="my10 center WidthFull">
          <table className="tbl">
            <thead>
              <tr>
                <th>Name</th>
                <th>View Users</th>
                <th>Published</th>
                {/* <th>Add User</th> */}
              </tr>
            </thead>
            <tbody>
              {institutes.map((institute, instituteEli) => (
                <tr
                  key={instituteEli}
                  className={`${
                    institute._id === currentInstituteId
                      ? "focus_lightGreen"
                      : ""
                  }`}
                >
                  <td>{institute.name}</td>
                  <td className="textCenter">
                    <button
                      className="simpleBorderBtn"
                      onClick={(e) =>
                        handleChangeUsersOfInstitute(e, institute)
                      }
                    >
                      View Users
                    </button>
                    {/* <Icon
                    icon={"basil:add-solid"}
                    className="iconifyGreenIcon"
                    
                    // onClick={(e) => setShowPopup("createUser")}
                  /> */}
                  </td>
                  <td className="textCenter">
                    {institute.isActive ? "Active " : "Not Active "}
                    {institute.isActive ? (
                      <button
                        className="mx5 dangerBtn"
                        onClick={() => {
                          toast.error("function is not created yet.");
                        }}
                      >
                        DeActivate Institute
                      </button>
                    ) : (
                      <button
                        className="mx5 primaryBtn"
                        onClick={() => handleActivateInstitute(institute)}
                      >
                        Activate Institute
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {showPopup === "createUser" && (
        <CreateUserPopup
          setShowPopup={setShowPopup}
          currentInstituteId={currentInstituteId}
        />
      )}
      {instituteUsers && (
        <div className="my10 center WidthFull">
          {/* {console.log("institute users =", instituteUsers)} */}
          <table className="tbl">
            <thead>
              <tr>
                <th>Name</th>
                <th>Mobile Number</th>
                <th>Email Id</th>
              </tr>
            </thead>
            <tbody>
              {instituteUsers.map((user, userEli) => (
                <tr key={userEli}>
                  <td>{user.name}</td>
                  <td>{user.mobileNumber}</td>
                  <td>{user.emailId}</td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td></td>
                <td className="textCenter">
                  <button
                    className="primaryBtn"
                    onClick={handleAddUserUnderInstitute}
                  >
                    Add User
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Institutes;
