import "./gpscQuestionHeight.css";
import React, { useEffect, useRef } from "react";

const GpscQuestionHeight = ({
  currentQuestion,
  currentQuestionNumber,
  // questionsHeight,
  setQuestionsHeight,
  // handleStopCalculateQuestionHeight,
  setQuestionHeightCalculated,
  totalQuestions,
  //   handleChangeAnswer,
  //   answers,
}) => {
  const currQuestionComponentRef = useRef(null);
  useEffect(() => {
    if (currQuestionComponentRef.current) {
      // console.log(
      //   "current question height =",
      //   currQuestionComponentRef.current.clientHeight
      // );
      setQuestionsHeight((prevValue) => {
        let updatedVal = {
          ...prevValue,
          [currentQuestionNumber]:
            currQuestionComponentRef.current.clientHeight,
        };
        // console.log("updated val = ", currentQuestionNumber, updatedVal);
        return updatedVal;
      });
    } else {
      console.log("height is not set.");
    }
    if (currentQuestionNumber && totalQuestions) {
      if (currentQuestionNumber >= totalQuestions) {
        // console.log("question heights =", questionsHeight);
        setQuestionHeightCalculated(true);
        // handleStopCalculateQuestionHeight();
      }
    }
  }, [
    currentQuestionNumber,
    // questionsHeight,
    setQuestionHeightCalculated,
    setQuestionsHeight,
    totalQuestions,
  ]);
  return (
    <div className="omrSingleQuestionMainDiv" ref={currQuestionComponentRef}>
      <div className="omrQuestion">
        <span className="omrQuestionSpan">
          {currentQuestionNumber}.{" "}
          {currentQuestion.questionType === "TEXT" ? (
            `${currentQuestion.question}`
          ) : (
            <div
              className="showRichText"
              dangerouslySetInnerHTML={{ __html: currentQuestion.question }}
            />
          )}
        </span>
      </div>
      <div className="omrQuestionOptions">
        {currentQuestion.options.map((el, eli) => {
          return (
            <div key={eli} className="omrQuestionSingleOption">
              <input
                type="radio"
                name={`que-${currentQuestionNumber}`}
                id={`que-${currentQuestionNumber}-${el.optionName}`}
                // checked={answers[currentQuestionNumber]?.answer?.includes(
                //   el.optionName
                // )}
                // onChange={() =>
                //   handleChangeAnswer(currentQuestionNumber, el.optionName)
                // }
              />
              <label
                htmlFor={`que-${currentQuestionNumber}-${el.optionName}`}
                className="omrQuestionSingleOptionLabel"
              >
                <span className="omrQuestionSingleOptionSpan">
                  {el.optionType === "TEXT" ? (
                    `${el.optionString}`
                  ) : (
                    <div
                      className="showRichText"
                      dangerouslySetInnerHTML={{
                        __html: el.optionString,
                      }}
                    />
                  )}
                </span>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GpscQuestionHeight;
