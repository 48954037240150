import { useSelector } from "react-redux";
import "./questionBank.css";
import React, { useEffect, useState } from "react";
import { getFetch } from "../../functions/fetch/fetch.crud";
import Loader from "../../components/common/loader/Loader";
import { AddQuestionPopup } from "./components/AddQuestionPopup";
import { UploadExcel } from "./components/UploadExcel";
import { toast } from "react-toastify";

export const QuestionBank = () => {
  const loggedUser = useSelector((state) => state.loggedUser.value);
  const loggedUserInstitute = useSelector((state) => state.institute.value);
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [page, setPage] = useState(1);
  const [addQuestionPopup, setAddQuestionPopup] = useState(false);
  const [uploadExcel, setUploadExcel] = useState(false);
  // const [getExcel, setGetExcel] = useState(false);
  const size = 10;
  // const optionNames = ["a", "b", "c", "d"];

  useEffect(() => {
    async function getPaginagedQuestions() {
      const response = await getFetch(
        `question/paginate?page=${page}&size=${size}`,
        loggedUserInstitute._id
        // loggedUser.instituteId
        // { "x-database-name": instituteId }
      );
      console.log("response =", response);
      setQuestions(response);
      setLoading(false);
    }
    if (loading && loggedUser) {
      getPaginagedQuestions();
    }
  }, [loading, loggedUser, loggedUserInstitute, page]);

  const handlePageChange = async (pageNumber) => {
    if (pageNumber <= 0) {
      toast.info("This is first page.");
      return;
    }
    const response = await getFetch(
      `question/paginate?page=${pageNumber}&size=${size}`,
      loggedUserInstitute._id
      // loggedUser.instituteId
      // { "x-database-name": instituteId }
    );
    if (response.error) {
      toast.error(response.message);
    } else if (response.length === 0) {
      toast.info("This is last page.");
    } else {
      setPage(pageNumber);
      setQuestions(response);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ padding: "10px" }}>
          {addQuestionPopup && (
            <AddQuestionPopup
              setAddQuestionPopup={setAddQuestionPopup}
              instituteId={loggedUserInstitute._id}
            />
          )}
          {uploadExcel && (
            <UploadExcel
              instituteId={loggedUserInstitute._id}
              setUploadExcel={setUploadExcel}
            />
          )}
          <section>
            <p>search</p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <button
                className="simpleBtn"
                onClick={() => setAddQuestionPopup(true)}
              >
                Add Question
              </button>
              {/* <div> */}
              {/* <button
                  className="simpleBtn"
                  onClick={() => {
                    setGetExcel(true);
                  }}
                  style={{ marginRight: "10px" }}
                >
                  Download Excel
                </button> */}
              <button
                className="simpleBtn"
                onClick={() => {
                  setUploadExcel(true);
                }}
              >
                Upload Excel
              </button>
              {/* </div> */}
            </div>
          </section>
          <section>
            <table
              style={{
                width: "100%",
                margin: "10px 0",
                minWidth: "300px",
                border: "1px solid #dddddd",
                borderRadius: "5px",
                padding: "5px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      //   width: "30px",
                      width: "48px",
                      borderBottom: "1px solid #dddddd",
                    }}
                  >
                    No.
                    {/* Select */}
                  </th>
                  {/* <th
                        style={{
                          width: "35px",
                          borderBottom: "1px solid #dddddd",
                        }}
                      >
                        Sr
                      </th> */}
                  <th style={{ borderBottom: "1px solid #dddddd" }}>
                    Question
                  </th>
                  <th
                    style={{
                      width: "70px",
                      borderBottom: "1px solid #dddddd",
                    }}
                  >
                    Answer
                  </th>
                </tr>
              </thead>
              <tbody>
                {questions?.map((questionEl, questionEli) => {
                  return (
                    <tr key={questionEli}>
                      <td
                        style={{
                          width: "48px",
                          borderBottom: "1px solid #dddddd",
                          textAlign: "center",
                        }}
                      >
                        {questionEli + 1 + (page - 1) * size}
                      </td>
                      {/* <td
                    style={{
                      width: "35px",
                      borderBottom: "1px solid #dddddd",
                      textAlign: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      name={questionEl._id}
                      id={questionEl._id}
                      //   checked={selectedQuestions.some(
                      //     (selectedObj) => selectedObj._id === questionEl._id
                      //   )}
                      //   onChange={() => {
                      //     const isSelected = selectedQuestions.some(
                      //       (selectedObj) => selectedObj._id === questionEl._id
                      //     );
                      //     if (isSelected) {
                      //       setSelectedQuestions(
                      //         selectedQuestions.filter(
                      //           (selectedObj) =>
                      //             selectedObj._id !== questionEl._id
                      //         )
                      //       );
                      //     } else {
                      //       if (
                      //         selectedQuestions.length >= maximumAllowedQuestions
                      //       ) {
                      //         alert("Maximum allowed questions reached.");
                      //       } else {
                      //         setSelectedQuestions([
                      //           ...selectedQuestions,
                      //           questionEl,
                      //         ]);
                      //       }
                      //     }
                      //   }}
                    />
                  </td> */}
                      {/* <td
                            style={{
                              width: "35px",
                              borderBottom: "1px solid #dddddd",
                              textAlign: "center",
                            }}
                          >
                            {(page - 1) * size + 1 + questionEli}
                          </td> */}
                      <td
                        style={{
                          padding: "0 5px",
                          borderBottom: "1px solid #dddddd",
                        }}
                      >
                        {questionEl.questionType === "TEXT" ? (
                          `${questionEl.question}`
                        ) : (
                          <div
                            className="showRichText"
                            // style={{ marginLeft: "50px" }}
                            // style={{ marginLeft: "10px" }}
                            dangerouslySetInnerHTML={{
                              __html: questionEl.question,
                            }}
                          />
                        )}
                      </td>
                      <td
                        style={{
                          width: "70px",
                          borderBottom: "1px solid #dddddd",
                          textAlign: "center",
                        }}
                      >
                        {questionEl.answer.join(",")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <button className="simpleBtn" onClick={() => handlePageChange(1)}>
                First Page
              </button>
              <button
                className="simpleBtn"
                onClick={() => handlePageChange(page - 1)}
              >
                Previous Page
              </button>
              <button
                className="simpleBtn"
                onClick={() => handlePageChange(page + 1)}
              >
                Next Page
              </button>
            </div>
          </section>
        </div>
      )}
    </>
  );
};
