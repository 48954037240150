// export const navMainUl = [
//   {
//     name: "Home",
//     nameWidth: 50,
//     link: "/",
//     icon: "ic:sharp-home",
//   },

//   {
//     name: "Offerings",
//     nameWidth: 95,

//     icon: "fluent-mdl2:product-variant",
//     childDropdown: [
//       {
//         name: "Test Series",
//         link: "/",
//         icon: "healthicons:i-exam-multiple-choice",
//       },
//       {
//         name: "Mock Test Series",
//         link: "/",
//         icon: "healthicons:i-exam-qualification",
//       },
//     ],
//   },
//   {
//     name: "My Profile",
//     nameWidth: 110,
//     icon: "iconamoon:profile-fill",
//     childDropdown: [
//       { name: "Dashboard", link: "/", icon: "mage:dashboard-bar-fill" },
//       { name: "My Purchases", link: "/", icon: "solar:cart-3-bold" },
//     ],
//   },
// ];
export const navMainUl = [
  {
    name: "Home",
    nameWidth: 50,
    link: "/",
    icon: "ic:sharp-home",
  },
  {
    name: "Question Bank",
    nameWidth: 120,
    link: "/questionbank",
    icon: "mdi:file-question",
  },
  {
    name: "Institutes",
    nameWidth: 80,
    link: "/institutes",
    icon: "mdi:school",
  },
  {
    name: "Offerings",
    nameWidth: 95,

    icon: "fluent-mdl2:product-variant",
    childDropdown: [
      {
        name: "Test Series",
        link: "/alltestseries",
        icon: "healthicons:i-exam-multiple-choice",
      },
      {
        name: "Mock Test Series",
        link: "/",
        icon: "healthicons:i-exam-qualification",
      },
    ],
  },
];
