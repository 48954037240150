// import React from "react";
// import "./home.css";
// import { useNavigate } from "react-router-dom";
// import { allTestSeries } from "../../constants/testSeries";
// import TestSeriesCard from "../../cards/TestSeriesCard/TestSeriesCard";
// import { Icon } from "@iconify/react/dist/iconify.js";

// const Home = () => {
//   const navigate = useNavigate();

//   const handleNavigateToPath = (path) => {
//     navigate(path);
//   };

//   // useEffect(() => {
//   //   async function fetchData() {
//   //     const testseries = await axios.get(
//   //       `${process.env.REACT_APP_API_URL}testseries`,
//   //       {
//   //         headers: {
//   //           "Content-Type": "application/json",
//   //           // "Access-Control-Allow-Origin": "*",
//   //           Authorization: "Bearer YOUR_ACCESS_TOKEN",
//   //         },
//   //       }
//   //     );
//   //     setTestSeries(testseries.data);
//   //     console.log("all testseries data =", testseries.data);
//   //   }
//   //   fetchData();
//   // }, []);

//   return (
//     <div className="homePageMainDiv">
//       <div
//         className="homeTopDiv"
//         style={{ height: "100px", width: "100%" }}
//       ></div>
//       <section className="homePageTestSeriesSection">
//         <div className="homePageTestSeriesSectionTitleDiv">
//           <span className="homePageTestSeriesSectionTitle">Test Series</span>
//           <span
//             className="homePageTestSeriesSectionViewAllTestSeries"
//             onClick={() => handleNavigateToPath("/alltestseries")}
//           >
//             View All
//             <Icon icon="ic:sharp-double-arrow" />
//           </span>
//         </div>

//         <div className="homePageAllTestSeriesDiv">
//           {/* {testSeries.map((el, eli) => {
//             return <TestSeriesCard key={eli} element={el} />;
//           })} */}
//           {allTestSeries.map((el, eli) => {
//             return <TestSeriesCard key={eli} element={el} />;
//           })}
//         </div>
//       </section>
//       {/* <SubmitOmrPopop /> */}
//     </div>
//   );
// };

// export default Home;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import CreateInstitutePopup from "../../components/popup/CreateInstitutePopup";
import { useNavigate } from "react-router-dom";

// import { JoditEditorComponent } from "../../components/richtext/JoditEditor/JoditEditor";

const Home = () => {
  const loggedUser = useSelector((state) => state.loggedUser.value);
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState("");
  return (
    <div style={{ padding: "5px" }}>
      https trial 1.
      {loggedUser && loggedUser.roles.includes("SUPER_ADMIN") && (
        <div>
          <div className="wrapFlex">
            <button
              className="primaryBtn"
              onClick={() => setShowPopup("createInstitute")}
            >
              Create Institute
            </button>
            <button
              className="primaryBtn"
              onClick={() => navigate("/institutes")}
            >
              All Institutes
            </button>
          </div>
          {showPopup === "createInstitute" && (
            <CreateInstitutePopup setShowPopup={setShowPopup} />
          )}
        </div>
      )}
      <div>{/* <JoditEditorComponent /> */}</div>
    </div>
  );
};

export default Home;
