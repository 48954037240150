import React from "react";

export const PriceDistributionHeader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        minWidth: "100%",
        width: "400px",
        height: "40px",
        marginTop: "10px",
        borderBottom: "2px solid rgba(0, 0, 0, 0.2)",
      }}
    >
      <span
        style={{
          fontWeight: "600",
          flex: "4",
          textAlign: "center",
        }}
      >
        Duration in Days
      </span>
      <span
        style={{
          fontWeight: "600",
          flex: "6",
          textAlign: "center",
        }}
      >
        Price
      </span>
    </div>
  );
};
