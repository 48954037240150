import React, { useState } from "react";
import { Popup } from "../common/popup-parent/popup";
import { Icon } from "@iconify/react/dist/iconify.js";
import { postFetch } from "../../functions/fetch/fetch.crud";
import { toast } from "react-toastify";

export const CreateUserPopup = ({ setShowPopup, currentInstituteId }) => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    isEmailVerified: false,
    mobileNumber: null,
    roles: [],
    partnerCategory: [],
    instituteId: currentInstituteId,
    isActive: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const roles = [
    "INSTITUTE_OWNER",
    "INSTITUTE_ADMIN",
    "FACULTY",
    "CLERK",
    "BRANCH_ADMIN",
  ];
  const partnerCategories = ["INTERNAL_TEAM", "INSTITUTE", "AFFILIATE"];

  function handleFormChange(e) {
    setUser({ ...user, [e.target.name]: e.target.value });
    return;
  }
  const handleCheckboxChange = (property, value) => {
    setUser((prevUser) => {
      const prevSelectedValues = prevUser[property];
      if (prevSelectedValues.includes(value)) {
        // If the value is already selected, remove it from the array
        const newValues = prevSelectedValues.filter(
          (selectedValue) => selectedValue !== value
        );
        return { ...prevUser, [property]: newValues };
      } else {
        // If the value is not selected, add it to the array
        const newValues = [...prevSelectedValues, value];
        return { ...prevUser, [property]: newValues };
      }
    });
  };

  function handleCancelInstituteCreate(e) {
    e.preventDefault();
    setShowPopup("");
    return;
  }
  async function handleSubmit(e) {
    e.preventDefault();
    console.log("submitting.", user);
    const response = await postFetch("user/createuser/institute", user);
    console.log("response for creating user under institute =", response);
    if (response.error) {
      toast.error(response.error);
      return;
    }
    toast.success(response.message);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
    return;
  }
  return (
    <Popup>
      <div className="columnFlex maxHeightFull maxWidthFull">
        <div className="globalFormDiv">
          <form className="globalPopupForm columnFlex">
            <div className="wrapFlex relative">
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                value={user.name}
                onChange={handleFormChange}
              />
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                value={user.email}
                onChange={handleFormChange}
              />
              <label htmlFor="password">
                <input
                  type={`${showPassword ? "text" : "password"}`}
                  name="password"
                  id="password"
                  placeholder="Password"
                  value={user.password}
                  onChange={handleFormChange}
                />
                <Icon
                  className="pointer"
                  style={{
                    position: "relative",
                    right: "25px",
                    top: "3px",
                    scale: "1.2",
                  }}
                  icon={`${
                    showPassword ? "mdi:eye-lock" : "heroicons:eye-16-solid"
                  }`}
                  onClick={() => setShowPassword(!showPassword)}
                />
              </label>
              <input
                type="number"
                name="mobileNumber"
                id="mobileNumber"
                placeholder="Mobile Number"
                value={user.mobileNumber}
                onChange={handleFormChange}
              />
            </div>
            <hr className="WidthFull" />
            <div className="wrapFlex relative">
              <span className="boldFont">Select Roles : </span>
              {roles.map((role) => (
                <div key={role}>
                  <label>
                    <input
                      type="checkbox"
                      value={role}
                      onChange={() => handleCheckboxChange("roles", role)}
                      checked={user.roles.includes(role)}
                    />{" "}
                    {role}
                  </label>
                </div>
              ))}
              <hr className="WidthFull" />
              <span className="boldFont">Select Roles : </span>
              {partnerCategories.map((partnerCategory) => (
                <div key={partnerCategory}>
                  <label>
                    <input
                      type="checkbox"
                      value={partnerCategory}
                      onChange={() =>
                        handleCheckboxChange("partnerCategory", partnerCategory)
                      }
                      checked={user.partnerCategory.includes(partnerCategory)}
                    />{" "}
                    {partnerCategory}
                  </label>
                </div>
              ))}
            </div>
          </form>
        </div>
        <div className="rowFlexBetween WidthFull">
          <button onClick={handleSubmit} className="primaryBtn">
            submit
          </button>
          <button onClick={handleCancelInstituteCreate} className="dangerBtn">
            Cancel
          </button>
        </div>
      </div>
    </Popup>
  );
};
