export function handleAddPart(setShowAddPartPopup, condition = true) {
  setShowAddPartPopup(condition);
}
// export function handleUpdatePart(groups, index) {
//   //
//   console.log("updating group.");
// }
// export function handleDeletePart(groups, index) {
//   //
//   console.log("deleting group.");
// }
