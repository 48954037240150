import React, { useState } from "react";
import { handleAddTest } from "../functions";
import { postFetch } from "../../../functions/fetch/fetch.crud";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export const AddTestPopup = ({
  setShowAddTestPopup,
  instituteId,
  offeringId,
  sectionName,
}) => {
  // const loggedUser = useSelector((state) => state.loggedUser.value);
  const loggedUserInstitute = useSelector((state) => state.institute.value);
  const [createTestForm, setCreateTestForm] = useState({
    name: "",
    numberOfQuestions: undefined,
    questions: [],
    groups: [],
    offeringId: offeringId,
    sectionName: sectionName,
    isFree: false,
    numberOfAttempts: undefined,
    instituteId: instituteId,
    isActive: false,
    offeringType: "TESTSERIES",
    duration: {
      hour: null,
      minute: null,
      second: null,
    },
  });

  function handleFormChange(e) {
    const { name, value } = e.target;
    setCreateTestForm({ ...createTestForm, [name]: value });
  }
  function handleFormDurationChange(e) {
    const { name, value } = e.target;
    setCreateTestForm({
      ...createTestForm,
      duration: { ...createTestForm.duration, [name]: value },
    });
  }

  function handleValidateForm() {
    if (createTestForm.name === "") {
      return false;
    }
    // if()
    return true;
  }

  return (
    <div
      className="createTestSeries_singleSectionDiv"
      style={{
        zIndex: 10,
        position: "fixed",
        // position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backdropFilter: "blur(5px)",
        background: "rgba(0,0,0,0.4)",
      }}
    >
      <div
        style={{
          // border: "1px solid rgba(0,0,0,0.7)",
          padding: "20px",
          borderRadius: "5px",
          background: "white",
          maxWidth: "90%",
        }}
      >
        {/* <span>{sectionIndex + 1}.</span> */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          <input
            style={{ width: "250px", padding: "10px" }}
            type="text"
            id="name"
            name="name"
            value={createTestForm.name}
            onChange={handleFormChange}
            placeholder="Enter Test Name"
          />
          <input
            style={{ width: "250px", padding: "10px" }}
            type="number"
            id="numberOfQuestions"
            name="numberOfQuestions"
            value={createTestForm.numberOfQuestions}
            onChange={handleFormChange}
            placeholder="Enter Number Of Questions"
          />
          <input
            style={{ width: "250px", padding: "10px" }}
            type="number"
            id="numberOfAttempts"
            name="numberOfAttempts"
            value={createTestForm.numberOfAttempts}
            onChange={handleFormChange}
            placeholder=" Number Of Allowed Attempts"
          />
          <select
            style={{ width: "250px", padding: "10px" }}
            placeholder="Is Test Free?"
            value={createTestForm.isFree}
            onChange={(e) => {
              setCreateTestForm({ ...createTestForm, isFree: e.target.value });
            }}
          >
            <option value="true">Free</option>
            <option value="false">Paid</option>
          </select>
        </div>
        <br />
        <hr />
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          Duration
          <input
            type="number"
            name="hour"
            id="hour"
            value={createTestForm.duration.hour}
            onChange={handleFormDurationChange}
            placeholder="Hour"
            style={{ width: "250px", padding: "10px" }}
          />
          <input
            type="number"
            name="minute"
            id="minute"
            value={createTestForm.duration.minute}
            placeholder="Minute"
            onChange={handleFormDurationChange}
            style={{ width: "250px", padding: "10px" }}
          />
          <input
            type="number"
            name="second"
            id="second"
            value={createTestForm.duration.second}
            placeholder="Second"
            onChange={handleFormDurationChange}
            style={{ width: "250px", padding: "10px" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "30px",
          }}
        >
          <button
            className="primaryBtn"
            onClick={async () => {
              const validation = handleValidateForm();
              // console.log("sending data =", createTestForm);
              if (validation) {
                // handleCreateTest();
                const response = await postFetch(
                  "test/create-and-update",
                  createTestForm,
                  loggedUserInstitute._id
                  // loggedUser.instituteId
                  // { "x-database-name": loggedUser._id }
                );
                if (response.error) {
                  console.log("response with error   =", response);
                  return toast.error(response.error[0]);
                } else {
                  console.log("response =", response);
                  toast.success(response.message);
                  handleAddTest(setShowAddTestPopup, false);
                  setTimeout(() => {
                    window.location.reload();
                  }, 2500);
                  return;
                }
              } else {
                return toast.error("Can't create Test.");
              }
            }}
            // onClick={() => console.log("test details =", createTestForm)}
            // onClick={async () => {
            //   if (sectionName.length > 0) {
            //     // console.log("updating...");
            //     const updatedCurrentTestSeries = {
            //       // ...currentTestSeries,
            //       sections: [...currentTestSeries.sections, sectionName],
            //     };
            //     console.log("sending =", updatedCurrentTestSeries);
            //     // const response = await postFetch(
            //     await postFetch(
            //       `testseries/dynamic/update/${currentTestSeries._id}`,
            //       updatedCurrentTestSeries
            //     );
            //     setShowAddSectionPopup(false);
            //     window.location.reload();
            //     // console.log("response.", response);
            //     // console.log("updated =", updatedCurrentTestSeries);
            //   } else {
            //     alert("Please enter section name");
            //   }
            // }}
          >
            Add
          </button>
          <button
            className="dangerBtn"
            onClick={() => handleAddTest(setShowAddTestPopup, false)}
          >
            Cancel
          </button>
        </div>
        {/* <Icon
        icon={"material-symbols:delete"}
        className="createTestSeries_priceDistributionDeleteOne"
        // onClick={() => handleRemoveSectionName(sectionIndex)}
      /> */}
      </div>
    </div>
  );
};
