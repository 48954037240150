// import axios from "axios";
import "./allTestSeries.css";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CreateTestSeriesPopup from "./createTestSeries/CreateTestSeries.popup";
import TestSeriesCard from "../../cards/TestSeriesCard/TestSeriesCard";
import { getFetch } from "../../functions/fetch/fetch.crud";
import Loader from "../../components/common/loader/Loader";

const AllTestSeries = () => {
  const loggedInUser = useSelector((state) => state.loggedUser.value);
  const loggedUserInstitute = useSelector((state) => state.institute.value);
  const [allTestSeries, setAllTestSeries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showCreateTestSeriesPopup, setShowCreateTestSeriesPopup] =
    useState(false);

  useEffect(() => {
    if (loading && loggedInUser) {
      async function getAllTestSeries() {
        // console.log(loggedInUser);
        // const token = localStorage.getItem("token");
        // console.log("token =", token);
        // const response = await axios.get(
        //   `${process.env.REACT_APP_API_URL}testseries/institute/${loggedInUser._id}`,
        //   {
        //     headers: {
        //       token,
        //     },
        //   }
        // );
        // console.log("logged user =", loggedInUser);
        const response = await getFetch(
          // `testseries/institute/${loggedInUser._id}`
          `testseries/institute/${loggedUserInstitute._id}`,
          loggedUserInstitute._id
          // { "x-database-name": loggedInUser._id }
        );
        if (response) {
          console.log("response =", response);
          setAllTestSeries(response.testseries);
          setLoading(false);
        }
        // if (response) {
        //   setAllTestSeries(response.data);
        //   console.log("response data =", response.data);
        //   setLoading(false);
        // }
      }
      getAllTestSeries();
    }
  }, [loading, loggedInUser, loggedUserInstitute]);

  function handleToggleShowCreateTestSeries() {
    setShowCreateTestSeriesPopup(!showCreateTestSeriesPopup);
  }
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {showCreateTestSeriesPopup ? (
            <div className="createTestSeriesPopup">
              <CreateTestSeriesPopup
                handleToggleShowCreateTestSeries={
                  handleToggleShowCreateTestSeries
                }
              />
            </div>
          ) : (
            <div className="allTestSeries_mainDiv">
              <h1 className="allTestSeriesTitle">Your TestSeries</h1>
              <section className="allTestSeriesFirstSection">
                <button
                  className="primaryBtn"
                  onClick={handleToggleShowCreateTestSeries}
                >
                  Create New TestSeries
                </button>
              </section>
              <section className="allTestSeries_allTestSeriesSection">
                {allTestSeries.map((testSeries, index) => {
                  return <TestSeriesCard key={index} element={testSeries} />;
                })}
              </section>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AllTestSeries;
