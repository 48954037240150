// functions
export function handleAddSection(setShowAddSectionPopup, condition = true) {
  setShowAddSectionPopup(condition);
}
export function handleSectionChange(index, setCurrentTestsIndex) {
  // console.log("changing section index to =", index);
  setCurrentTestsIndex(index);
}
export function handleAddTest(setShowAddTestPopup, condition = true) {
  setShowAddTestPopup(condition);
}
