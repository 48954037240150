import React, { useState } from "react";
import { Popup } from "../common/popup-parent/popup";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react/dist/iconify.js";
import { postFormFetch } from "../../functions/fetch/fetch.crud";

const CreateInstitutePopup = ({ setShowPopup }) => {
  const [instituteForm, setinstituteForm] = useState({
    name: "",
    // poster: "",
    razorpayId: "",
    isRazorpayIdVerified: false,
    address: [""],
    email: "",
    mobileNumber: null,
    // logo: "",
    gstNumber: "",
    googleMapAddressLink: "",
    isActive: false,
    isDisabled: false,
  });
  const [posterFile, setPosterFile] = useState(null);
  const [posterPreviewUrl, setPosterPreviewUrl] = useState(null);
  const [logoFile, setlogoFile] = useState(null);
  const [logoPreviewUrl, setLogoPreviewUrl] = useState(null);
  function handleFormChange(e) {
    setinstituteForm({
      ...instituteForm,
      [e.target.name]: e.target.value,
    });
    return;
  }
  function handleAddressChange(e, index) {
    e.preventDefault();
    setinstituteForm({
      ...instituteForm,
      address: instituteForm.address.map((item, i) =>
        i === index ? e.target.value : item
      ),
    });
  }
  function handleRemoveAddress(e, index) {
    e.preventDefault();
    if (instituteForm.address.length > 1) {
      setinstituteForm({
        ...instituteForm,
        address: instituteForm.address.filter((item, i) => i !== index),
      });
      return;
    }
    toast.error("Address Can Not Be Empty.");
  }
  function handleAddAddress(e) {
    e.preventDefault();
    setinstituteForm({
      ...instituteForm,
      address: [...instituteForm.address, ""],
    });
  }
  function handlePosterChange(e, remove = false) {
    if (remove) {
      setPosterFile(null);
      setPosterPreviewUrl(null);
      return;
    }

    const file = e.target.files[0];
    if (file) {
      setPosterFile(file);

      // Create a preview URL
      const objectUrl = URL.createObjectURL(file);
      setPosterPreviewUrl(objectUrl);

      // Revoke the object URL when the component is unmounted or when a new file is selected
      return () => URL.revokeObjectURL(objectUrl);
    }
  }
  function handleLogoChange(e, remove = false) {
    if (remove) {
      setlogoFile(null);
      setLogoPreviewUrl(null);
      return;
    }

    const file = e.target.files[0];
    if (file) {
      setlogoFile(file);

      // Create a preview URL
      const objectUrl = URL.createObjectURL(file);
      setLogoPreviewUrl(objectUrl);

      // Revoke the object URL when the component is unmounted or when a new file is selected
      return () => URL.revokeObjectURL(objectUrl);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (!posterFile || !logoFile) {
      toast.error("Please select a poster and logo.");
      return;
    }
    const formData = new FormData();
    formData.append("posterFile", posterFile);
    formData.append("logoFile", logoFile);
    formData.append("jsonData", JSON.stringify(instituteForm));
    // console.log("submitting.");
    try {
      const response = await postFormFetch("institute", formData);
      console.log("response =", response);
      if (response.error) {
        toast.error(response.error);
      } else {
        toast.success("Institute created successfully.");
        return setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      console.log("error while creating institute =", error);
    }
    return;
  }
  function handleCancelInstituteCreate(e) {
    e.preventDefault();
    setShowPopup("");
    return;
  }
  return (
    <Popup>
      <div className="columnFlex maxHeightFull maxWidthFull">
        <div className="globalFormDiv">
          <form
            className="globalPopupForm columnFlex"
            // style={{ maxHeight: "calc(100% - 90px)" }}
          >
            <p className="boldFont">Basic Informations.</p>
            <div className="wrapFlex">
              <input
                type="text"
                name="name"
                id="name"
                value={instituteForm.name}
                onChange={handleFormChange}
                placeholder="Name Of Institute"
              />
              <input
                type="text"
                name="razorpayId"
                id="razorpayId"
                value={instituteForm.razorpayId}
                onChange={handleFormChange}
                placeholder="Razorpay Id"
              />
              <input
                type="email"
                name="email"
                id="email"
                value={instituteForm.email}
                onChange={handleFormChange}
                placeholder="Email"
              />
              <input
                type="number"
                name="mobileNumber"
                id="mobileNumber"
                value={instituteForm.mobileNumber}
                onChange={handleFormChange}
                placeholder="Mobile Number"
              />
              <input
                type="text"
                name="gstNumber"
                id="gstNumber"
                value={instituteForm.gstNumber}
                onChange={handleFormChange}
                placeholder="Gst Number"
              />
              <input
                type="text"
                name="googleMapAddressLink"
                id="googleMapAddressLink"
                value={instituteForm.googleMapAddressLink}
                onChange={handleFormChange}
                placeholder="Google Map Address Link"
              />
            </div>
            <hr style={{ width: "100%" }} />
            <p className="boldFont">Address</p>

            <div className="wrapFlex">
              <div className="columnFlex">
                {instituteForm.address.map((addressEl, addressEli) => {
                  return (
                    <div
                      key={addressEli}
                      className="rowFlexAround overflowXAuto maxWidth90"
                    >
                      <input
                        type="text"
                        name={`address-${addressEli}`}
                        id={`address-${addressEli}`}
                        value={addressEl}
                        onChange={(e) => handleAddressChange(e, addressEli)}
                        placeholder={`Line ${addressEli + 1}`}
                      />
                      <Icon
                        icon={"material-symbols:delete"}
                        className="iconifyRedIcon"
                        onClick={(e) => handleRemoveAddress(e, addressEli)}
                      />
                      <Icon
                        icon={"basil:add-solid"}
                        className="iconifyGreenIcon"
                        onClick={(e) => handleAddAddress(e, addressEli)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <hr style={{ width: "100%" }} />
            <div
              className="rowFlexEvenly WidthFull"
              style={{ flexWrap: "wrap" }}
            >
              <div className="columnFlex">
                <label htmlFor="posterFile">
                  <span className="boldFont">Poster Image</span>{" "}
                  <input
                    type="file"
                    name="posterFile"
                    id="posterFile"
                    // value={posterFile}
                    onChange={handlePosterChange}
                  />
                </label>
                {posterPreviewUrl && (
                  <img
                    src={posterPreviewUrl}
                    alt="Poster preview"
                    style={{ width: "200px", height: "150px" }}
                  />
                )}
              </div>
              <div className="columnFlex">
                <label htmlFor="logoFile">
                  <span className="boldFont">Logo Image</span>{" "}
                  <input
                    type="file"
                    name="logoFile"
                    id="logoFile"
                    // value={posterFile}
                    onChange={handleLogoChange}
                  />
                </label>
                {logoPreviewUrl && (
                  <img
                    src={logoPreviewUrl}
                    alt="Logo preview"
                    style={{ width: "150px", height: "150px" }}
                  />
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="rowFlexBetween">
          <button onClick={handleSubmit} className="primaryBtn">
            submit
          </button>
          <button onClick={handleCancelInstituteCreate} className="dangerBtn">
            Cancel
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default CreateInstitutePopup;
