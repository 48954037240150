import React, { useState } from "react";
import "./mobileWidthHeader.css";
import { Icon } from "@iconify/react";
import { navMainUl } from "../../../../constants/header";
// import { Icon } from "@iconify/react/dist/iconify.js";

const MobileWidthHeader = ({ handleNavigate, userName }) => {
  const [showNavMenu, setShowNavMenu] = useState(false);
  const [showMobileDropDown, setshowMobileDropDown] = useState("");

  const handleShowNavMenu = (conditionBool) => {
    setShowNavMenu(conditionBool);
    if (!conditionBool) {
      setshowMobileDropDown("");
    }
  };
  const handleShowDropDown = (name) => {
    if (showMobileDropDown === name) {
      setshowMobileDropDown("");
    } else setshowMobileDropDown(name);
  };
  // useEffect(() => {}, [userName]);
  return (
    <>
      <div className="mobileHeaderMainDiv">
        <span className="mobileNameHeader">निदर्शन</span>
        {/* <span className="mobileNameHeader">Nidarshan</span> */}
        {!showNavMenu ? (
          <Icon
            className="mobileShowMenuIcon"
            icon="solar:hamburger-menu-bold"
            onClick={() => handleShowNavMenu(true)}
          />
        ) : (
          <Icon
            className="mobileHideMenuIcon"
            icon="maki:cross"
            onClick={() => handleShowNavMenu(false)}
          />
        )}
      </div>
      {showNavMenu && (
        <div
          className="mobileNavBarMainDiv"
          style={{ height: "calc(100vh - 60px)" }}
        >
          <div
            className="mobileNavBarBoxDiv"
            style={{ textTransform: "capitalize" }}
          >
            {navMainUl.map((el, eli) => {
              return (
                <div className="mobileSingleNavFullDiv" key={eli}>
                  <div
                    className={`${
                      showMobileDropDown === el.name &&
                      el.childDropdown?.length > 0
                        ? "mobileSingleNavMainDivActive"
                        : "mobileSingleNavMainDiv"
                    }`}
                    onClick={() => {
                      // if (el.childDropdown?.length > 0) {
                      handleShowDropDown(el.name);
                      if (el.link) {
                        handleNavigate(el.link);
                        setShowNavMenu(false);
                      }
                      // }
                    }}
                  >
                    <div className="mobileSingleNavOnlyNameAndIcon">
                      <Icon
                        className="mobileSingleNavNameIcon"
                        icon={el.icon}
                      />
                      <span className="mobileSingleNavNameSpan">{el.name}</span>
                    </div>
                    {el.childDropdown?.length > 0 && (
                      <Icon
                        className="mobileSingleNavDropDownIcon"
                        icon="eva:arrow-left-fill"
                        // onClick={() => handleShowDropDown(el.name)}
                      />
                    )}
                  </div>
                  {showMobileDropDown === el.name &&
                    el.childDropdown?.length > 0 && (
                      <div className="mobileDropdownMainDiv">
                        {el.childDropdown.map((nel, neli) => {
                          return (
                            <div
                              key={neli}
                              className="mobileSingleDropDown"
                              onClick={() => {
                                console.log("clicked =", nel.name);
                                if (nel.link) {
                                  handleNavigate(nel.link);
                                  setShowNavMenu(false);
                                }
                              }}
                            >
                              <Icon
                                className="mobileSingleNavDropDownIcon"
                                icon={nel.icon}
                                // onClick={() => handleShowDropDown(el.name)}
                              />
                              <span>{nel.name}</span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                </div>
              );
            })}
            <div className="mobileSinglesignInOutMainDiv">
              <div
                className="mobileSinglesignInOutOnlyNameAndIcon"
                onClick={() => {
                  handleNavigate("login");
                  setShowNavMenu(false);
                }}
              >
                <Icon className="signInOutIcon" icon="lets-icons:sign-in" />
                <span className="signInOutName">
                  {userName ? userName.name : "Sign In"}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileWidthHeader;
