import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  getFetch,
  postFetch,
  // postFetch,
  updateFetch,
} from "../../functions/fetch/fetch.crud";
import Loader from "../../components/common/loader/Loader";
import { handleAddPart } from "./functions";
import { AddPartPopup } from "./components/AddPartPopup";
import { Icon } from "@iconify/react/dist/iconify.js";
import { ManageQuestionsPopup } from "./components/ManageQuestionsPopup";
import { CurrentPartQuestionsPopup } from "./components/CurrentPartQuestionsPopup";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import GpscQuestionHeight from "../../components/omr-question/gpsc/GpscQuestionHeight";

const SingleTest = () => {
  const location = useLocation();
  const loggedUser = useSelector((state) => state.loggedUser.value);
  const loggedUserInstitute = useSelector((state) => state.institute.value);
  const [loading, setLoading] = useState(true);
  const [showCurrentPartQuestionsPopup, setShowCurrentPartQuestionsPopup] =
    useState(false);
  const [showAddPartPopup, setShowAddPartPopup] = useState(false);
  const [showManageQuestionsPopup, setShowManageQuestionsPopup] =
    useState(false);
  const [test, setTest] = useState(null);
  const [updateInPopup, setUpdateInPopup] = useState(false);
  const [maximumAllowedQuestions, setMaximumAllowedQuestions] =
    useState(undefined);
  const [groupIndex, setGroupIndex] = useState(undefined);
  // const [testPart, setTestPart] = useState(null);
  const [testErrors, setTestErrors] = useState(null);
  const [readyToPublish, setReadyToPublish] = useState(false);
  const [errorResponseTestQuestions, setErrorResponseTestQuestions] = useState(
    []
  );
  const [questionsHeight, setQuestionsHeight] = useState(null);
  const [questionHeightCalculated, setQuestionHeightCalculated] =
    useState(false);

  useEffect(() => {
    const SearchParams = new URLSearchParams(location.search);

    async function fetchTest(id) {
      const response = await getFetch(
        `test/dynamic/${id}`,
        loggedUserInstitute._id
        // loggedUser.instituteId
        //  {        "x-database-name": loggedUser._id  }
      );
      console.log("test details =", response);
      setTest(response);
      setLoading(false);
      return;
    }

    if (loading && SearchParams.get("id") && loggedUser) {
      if (!test) {
        fetchTest(SearchParams.get("id"));
      }
    }
  }, [loading, location, loggedUser, loggedUserInstitute, test]);

  async function handleShowCurrentPartQuestions(index) {
    setGroupIndex(index);
    setShowCurrentPartQuestionsPopup(true);
  }

  // function handleStopCalculateQuestionHeight() {
  //   console.log(questionsHeight);
  //   setQuestionHeightCalculated(false);
  // }

  async function handleCheckErrors() {
    const response = await getFetch(
      `test/dynamic/check-test/${test._id}`,
      loggedUserInstitute._id
      // loggedUser.instituteId
    );
    console.log("response =", response);
    if (response.error) {
      toast.error(response.message);
      setTestErrors(response.error);
    } else {
      toast.success(response.message);
      setTimeout(() => {
        if (response.theme === "OMR" && response.populatedTest) {
          const testQuestions = [];
          response.populatedTest.groups.forEach((group) => {
            testQuestions.push(...group.questions);
          });
          // console.log(
          //   "test questions for calculating height of questions =",
          //   testQuestions
          // );
          setErrorResponseTestQuestions(testQuestions);
        } else {
          setReadyToPublish(true);
        }
      }, 2500);
    }
  }

  async function handlePublishTest() {
    console.log("publishing dummy function.");
    const response = await postFetch(
      `test/dynamic/publish-test/${test._id}`,
      questionsHeight ? { ...test, questionsHeight } : { test },
      loggedUserInstitute._id
      // ...(questionsHeight ? { ...test, questionsHeight } : { test })
    );
    console.log("response = ", response);
    if (response.error) {
      toast.error(response.error);
      return;
    } else {
      toast.success(response.message);
      return;
    }
  }

  // async function handleAddTestPart() {}

  // const location = new URLSearchParams()
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ padding: "10px" }}>
          {testErrors && (
            <div
              style={{
                border: "1px solid rgba(255,0,0,0.4)",
                padding: "15px 10px",
                borderRadius: "5px",
                textTransform: "capitalize",
                fontWeight: "600",
                marginBottom: "10px",
                color: "red",
              }}
            >
              <h2>Errors</h2>
              {/* <ol> */}
              {testErrors.map((testError, testErrorIndex) => {
                return (
                  <div key={testErrorIndex}>
                    <p>- {testError}</p>
                    {/* <li key={testErrorIndex}>{testError}</li> */}
                  </div>
                );
              })}
              {/* </ol> */}
            </div>
          )}
          {showCurrentPartQuestionsPopup && (
            <CurrentPartQuestionsPopup
              questionIds={test.groups[groupIndex]?.questions}
              setShowCurrentPartQuestionsPopup={
                setShowCurrentPartQuestionsPopup
              }
              setGroupIndex={setGroupIndex}
              instituteId={loggedUserInstitute._id}
            />
          )}
          {showAddPartPopup && (
            <AddPartPopup
              setShowAddPartPopup={setShowAddPartPopup}
              currentTest={test}
              index={updateInPopup}
              setUpdateInPopup={setUpdateInPopup}
              instituteId={loggedUserInstitute._id}
            />
          )}
          {showManageQuestionsPopup && (
            <ManageQuestionsPopup
              setShowManageQuestionsPopup={setShowManageQuestionsPopup}
              maximumAllowedQuestions={maximumAllowedQuestions}
              setMaximumAllowedQuestions={setMaximumAllowedQuestions}
              test={test}
              // testId={test._id}
              // groups={test.groups}
              groupIndex={groupIndex}
              setGroupIndex={setGroupIndex}
              instituteId={loggedUserInstitute._id}
            />
          )}
          <section
            style={{
              border: "1px solid rgba(0,0,0,0.4)",
              padding: "15px 10px",
              borderRadius: "5px",
              textTransform: "capitalize",
              fontWeight: "600",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              //   flexDirection: "column",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                flex: "1",
                minWidth: "180px",
              }}
            >
              <p>Name : {test.name}</p>
              <p>Questions : {test.numberOfQuestions}</p>
              <p>Allowed Attempts : {test.numberOfAttempts}</p>
              <p>
                Duration :{" "}
                {test.duration.hour > 9
                  ? test.duration.hour
                  : `0${test.duration.hour}`}
                :
                {test.duration.minute > 9
                  ? test.duration.minute
                  : `0${test.duration.minute}`}
                :
                {test.duration.second > 9
                  ? test.duration.second
                  : `0${test.duration.second}`}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                flex: "1",
                minWidth: "150px",
                alignItems: "flex-end",
              }}
            >
              <p
                style={{
                  ...(test.isFree ? { color: "green" } : { color: "red" }),
                }}
              >
                {test.isFree ? "Free Test" : "Paid Test"}
              </p>
              <p
                style={{
                  ...(test.isActive ? { color: "green" } : { color: "red" }),
                }}
              >
                {/* {test.isActive ? "Published" : "Not Published"} */}
                {test.isActive ? (
                  "Test is Active."
                ) : readyToPublish ||
                  (errorResponseTestQuestions && questionHeightCalculated) ? (
                  <button className="primaryBtn" onClick={handlePublishTest}>
                    Publish Now
                    {/* Activate Now */}
                  </button>
                ) : (
                  <button className="primaryBtn" onClick={handleCheckErrors}>
                    Check Errors
                    {/* Activate Now */}
                  </button>
                )}
              </p>
              {/* <button className="primaryBtn" style={{ width: "100%" }}>
                Update
              </button> */}
            </div>
          </section>
          <section
            style={{
              border: "1px solid rgba(0,0,0,0.4)",
              padding: "15px 10px",
              borderRadius: "5px",
              margin: "10px 0",
            }}
          >
            <p
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "1.5rem", fontWeight: "700" }}>
                Parts
              </span>
              <button
                className="primaryBtn"
                onClick={() => handleAddPart(setShowAddPartPopup)}
              >
                Add Part
              </button>
            </p>
            <div>
              <div style={{ width: "100%", overflowX: "auto" }}>
                <table
                  style={{
                    width: "100%",
                    margin: "10px 0",
                    minWidth: "720px",
                    border: "1px solid #dddddd",
                    borderRadius: "5px",
                    padding: "5px",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderBottom: "1px solid #dddddd",
                          padding: "0 5px",
                        }}
                      >
                        Sr No.
                      </th>
                      <th
                        style={{
                          borderBottom: "1px solid #dddddd",
                          padding: "0 5px",
                        }}
                      >
                        Name
                      </th>
                      <th
                        style={{
                          borderBottom: "1px solid #dddddd",
                          padding: "0 5px",
                        }}
                      >
                        Number Of Questions
                      </th>
                      <th
                        style={{
                          borderBottom: "1px solid #dddddd",
                          padding: "0 5px",
                        }}
                      >
                        Assigned Questions
                      </th>
                      <th
                        style={{
                          borderBottom: "1px solid #dddddd",
                          padding: "0 5px",
                        }}
                      >
                        Manage Questions
                      </th>
                      <th
                        style={{
                          borderBottom: "1px solid #dddddd",
                          padding: "0 5px",
                        }}
                      >
                        Update
                      </th>
                      <th
                        style={{
                          borderBottom: "1px solid #dddddd",
                          padding: "0 5px",
                        }}
                      >
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {test.groups?.map((groupEl, groupEli) => {
                      return (
                        // <div key={groupEli}>
                        <tr key={groupEli}>
                          <td
                            style={{
                              borderBottom: "1px solid #dddddd",
                              textAlign: "center",
                            }}
                          >
                            {groupEli + 1}
                          </td>
                          <td
                            style={{
                              borderBottom: "1px solid #dddddd",
                              textAlign: "center",
                            }}
                          >
                            {groupEl.name}
                          </td>
                          <td
                            style={{
                              borderBottom: "1px solid #dddddd",
                              textAlign: "center",
                            }}
                          >
                            {groupEl.numberOfQuestions}
                          </td>
                          <td
                            style={{
                              borderBottom: "1px solid #dddddd",
                              textAlign: "center",
                            }}
                          >
                            {groupEl.questions?.length}
                          </td>
                          <td
                            style={{
                              borderBottom: "1px solid #dddddd",
                              textAlign: "center",
                              // display: "flex",
                              // justifyContent: "center",
                              // alignItems: "center",
                            }}
                          >
                            <Icon
                              icon={"mingcute:pencil-2-fill"}
                              // icon={"radix-icons:pencil-2"}
                              style={{
                                color: "green",
                                cursor: "pointer",
                                marginRight: "10px",
                              }}
                              onClick={() => {
                                setShowManageQuestionsPopup(true);
                                setMaximumAllowedQuestions(
                                  groupEl.numberOfQuestions
                                );
                                setGroupIndex(groupEli);
                              }}
                              // onClick={async () => {
                              //   const resopnse = await getFetch(
                              //     "question/paginate?page=2&size=4"
                              //   );
                              //   console.log("resopnse =", resopnse);
                              // }}
                            />{" "}
                            <button
                              className="simpleBtn"
                              style={{ marginTop: "5px", marginBottom: "5px" }}
                              onClick={() =>
                                handleShowCurrentPartQuestions(groupEli)
                              }
                            >
                              Questions
                            </button>
                          </td>
                          <td
                            style={{
                              borderBottom: "1px solid #dddddd",
                              textAlign: "center",
                            }}
                          >
                            <Icon
                              icon={"mingcute:pencil-2-fill"}
                              // icon={"radix-icons:pencil-2"}
                              style={{ color: "green", cursor: "pointer" }}
                              // onClick={() => {}}
                              onClick={() => {
                                setUpdateInPopup(groupEli);
                                setShowAddPartPopup(true);
                                // handleUpdatePart(test.groups, groupEli);
                              }}
                            />
                          </td>
                          <td
                            style={{
                              borderBottom: "1px solid #dddddd",
                              textAlign: "center",
                            }}
                          >
                            <Icon
                              icon={"material-symbols:delete"}
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={async () => {
                                const confirm = window.confirm(
                                  "Are you sure, You want to delete the Part?"
                                );
                                if (confirm) {
                                  const { groups } = test;
                                  const updatedGroups = groups.filter(
                                    (_, ind) => ind !== groupEli
                                  );
                                  // console.log("updated groups =", updatedGroups);
                                  const response = await updateFetch(
                                    `test/dynamic/${test._id}`,
                                    { groups: updatedGroups },
                                    loggedUserInstitute._id
                                    // loggedUser.instituteId
                                    // { "x-database-name": loggedUser._id }
                                  );
                                  console.log("response =", response);
                                  if (response) {
                                    window.location.reload();
                                  } else {
                                    alert("Can't Add Part.");
                                  }
                                }
                                // handleDeletePart(test.groups, groupEli);
                              }}
                            />
                          </td>
                        </tr>
                        // </div>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          {errorResponseTestQuestions && !questionHeightCalculated && (
            <div style={{ opacity: 0 }}>
              <div style={{ width: "555px" }}>
                {errorResponseTestQuestions.map((questionEl, questionEli) => {
                  return (
                    <GpscQuestionHeight
                      key={questionEli}
                      currentQuestion={questionEl}
                      currentQuestionNumber={questionEli + 1}
                      setQuestionsHeight={setQuestionsHeight}
                      // questionsHeight={questionsHeight}
                      // handleStopCalculateQuestionHeight={
                      //   handleStopCalculateQuestionHeight
                      // }
                      setQuestionHeightCalculated={setQuestionHeightCalculated}
                      totalQuestions={errorResponseTestQuestions.length}
                    />
                  );
                })}
              </div>
            </div>
          )}
          <button
            className="simpleBtn"
            onClick={() => console.log("question heights =", questionsHeight)}
          >
            get question heights
          </button>
        </div>
      )}
    </>
  );
};

export default SingleTest;
