import { useState } from "react";
import { handleAddPart } from "../functions";
import { updateFetch } from "../../../functions/fetch/fetch.crud";
import { toast } from "react-toastify";

export function AddPartPopup({
  setShowAddPartPopup,
  currentTest,
  index = false,
  setUpdateInPopup,
  instituteId,
}) {
  const [part, setPart] = useState({
    name: index !== false ? currentTest.groups[index].name : "",
    numberOfQuestions:
      index !== false ? currentTest.groups[index].numberOfQuestions : undefined,
  });
  // console.log("index =", index);
  // console.log("current group =", currentTest.groups[index].name);
  // console.log("current group =", currentTest.groups[index].numberOfQuestions);
  return (
    <div
      className="createTestSeries_singleSectionDiv"
      style={{
        zIndex: 10,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backdropFilter: "blur(5px)",
        background: "rgba(0,0,0,0.4)",
      }}
    >
      <div
        style={{
          // border: "1px solid rgba(0,0,0,0.7)",
          padding: "20px",
          borderRadius: "5px",
          background: "white",
          // display: "flex",
          // gap: "10px",
        }}
      >
        {/* <span>{sectionIndex + 1}.</span> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          <input
            style={{ width: "250px", padding: "10px" }}
            type="text"
            id="name"
            name="name"
            value={part.name}
            onChange={(e) =>
              setPart({ ...part, [e.target.name]: e.target.value })
            }
            placeholder="Enter Part Name"
          />
          <input
            style={{ width: "250px", padding: "10px" }}
            type="number"
            id="numberOfQuestions"
            name="numberOfQuestions"
            max={currentTest.numberOfQuestions}
            min={0}
            value={part.numberOfQuestions}
            onChange={(e) =>
              setPart({ ...part, [e.target.name]: e.target.value })
            }
            placeholder="Number of questions"
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "30px",
          }}
        >
          <button
            className="primaryBtn"
            onClick={async () => {
              const { groups } = currentTest;
              if (index !== false) {
                groups[index] = { ...groups[index], ...part };
                // console.log("updated groups =", groups[index]);
              } else {
                //   groups[index] = part;
                //   console.log("updated groups for fetch =", groups);
                // } else {
                groups.push(part);
                // }
              }
              const response = await updateFetch(
                `test/dynamic/${currentTest._id}`,
                { groups },
                instituteId
                // { "x-database-name": instituteId }
              );
              console.log("response =", response);
              if (response.error) {
                toast.error(response.error);
              }
              if (response) {
                toast.success("Test updated successfully");
                return setTimeout(() => {
                  window.location.reload();
                }, 2500);
              } else {
                return toast.error("Can't Add Part.");
              }
            }}
          >
            Add
          </button>
          <button
            className="dangerBtn"
            onClick={() => {
              setUpdateInPopup(false);
              handleAddPart(setShowAddPartPopup, false);
            }}
          >
            Cancel
          </button>
        </div>
        {/* <Icon
        icon={"material-symbols:delete"}
        className="createTestSeries_priceDistributionDeleteOne"
        // onClick={() => handleRemoveSectionName(sectionIndex)}
      /> */}
      </div>
    </div>
  );
}
