import { useSelector } from "react-redux";
import "./createTestSeries.popup.css";
import React, { useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
// import axios from "axios";
import {
  postFetch,
  postFormFetch,
  updateFetch,
  updateFormFetch,
} from "../../../functions/fetch/fetch.crud";
import { toast } from "react-toastify";
import { JoditEditorComponent } from "../../../components/richtext/JoditEditor/JoditEditor";
// import { toast } from "react-toastify";

const CreateTestSeriesPopup = ({
  handleToggleShowCreateTestSeries,
  currentTestSeries = null,
  updateTestSeries = false,
}) => {
  const loggedInUser = useSelector((state) => state.loggedUser.value);
  const loggedUserInstitute = useSelector((state) => state.institute.value);
  // console.log("loggedUserInstitute =", loggedUserInstitute);
  // const [testSeriesForm, setTestSeriesForm] = useState({
  //   name: currentTestSeries?.name ?? "",
  //   publishedBy: currentTestSeries?.publishedBy ?? loggedInUser?.name,
  //   priceForDuration: currentTestSeries?.priceForDuration ?? [],
  //   expiry:
  //     new Date(currentTestSeries?.expiry).toISOString().slice(0, 16) ?? "",
  //   instituteId: currentTestSeries?.instituteId ?? loggedInUser?._id,
  //   about: currentTestSeries?.about ?? "",
  //   description: currentTestSeries?.description ?? "",
  //   theme: currentTestSeries?.theme ?? "OMR",
  //   sections: currentTestSeries?.sections ?? [],
  //   tests:
  //     currentTestSeries?.tests?.map((test) => {
  //       return test._id;
  //     }) ?? [], //
  //   poster: currentTestSeries?.poster ?? "",
  //   isActive: currentTestSeries?.isActive ?? false,
  //   mrp: currentTestSeries?.mrp ?? null,
  //   nsp: currentTestSeries?.nsp ?? null,
  // });
  const [testSeriesForm, setTestSeriesForm] = useState(() => ({
    name: currentTestSeries?.name || "",
    isPublic: currentTestSeries?.isPublic || true,
    publishedBy:
      currentTestSeries?.publishedBy || loggedUserInstitute?.name || "",
    priceForDuration: currentTestSeries?.priceForDuration || [],
    expiry: currentTestSeries?.expiry
      ? new Date(currentTestSeries.expiry).toISOString().slice(0, 16)
      : "",
    instituteId:
      currentTestSeries?.instituteId || loggedUserInstitute?._id || "",
    about: currentTestSeries?.about || "",
    description: currentTestSeries?.description || "",
    theme: currentTestSeries?.theme || "OMR",
    sections: currentTestSeries?.sections || ["default"],
    tests: currentTestSeries?.tests
      ? currentTestSeries.tests.map((test) => test._id)
      : [],
    // posterFile: null,
    posterPath: currentTestSeries?.posterPath || "",
    // poster: currentTestSeries?.poster || "",
    isActive: currentTestSeries?.isActive || false,
    mrp: currentTestSeries?.mrp ?? null,
    nsp: currentTestSeries?.nsp ?? null,
  }));
  const [posterFile, setPosterFile] = useState(null);
  const [posterPreview, setPosterPreview] = useState(
    currentTestSeries?.posterPath || null
  );

  function handleFormInputChange(e) {
    const { name, value } = e.target;
    // console.log("name =", name, typeof name);
    // console.log("value =", value, typeof value);
    if (name === "isPublic") {
      const updatedValue =
        value === "true" ? true : value === "false" ? false : value;
      // console.log("updated value =", {
      //   ...testSeriesForm,
      //   [name]: updatedValue,
      // });
      setTestSeriesForm({ ...testSeriesForm, [name]: updatedValue });

      return;
    }
    // console.log("updated form =", { ...testSeriesForm, [name]: value });
    setTestSeriesForm({ ...testSeriesForm, [name]: value });
    return;
  }

  // function handleFormFileChange(e) {
  //   const { name, files } = e.target;
  //   setTestSeriesForm({ ...testSeriesForm, [name]: files[0] });
  // }
  function handlePosterChange(e, remove = false) {
    if (remove) {
      setPosterFile(null);
      setPosterPreview(null);
      return;
    }

    const file = e.target.files[0];
    if (file) {
      setPosterFile(file);

      // Create a preview URL
      const objectUrl = URL.createObjectURL(file);
      setPosterPreview(objectUrl);

      // Revoke the object URL when the component is unmounted or when a new file is selected
      return () => URL.revokeObjectURL(objectUrl);
    }
  }
  // function handleFormFileChange(e) {
  //   const { name, files } = e.target;
  //   const file = files[0];

  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setTestSeriesForm({
  //         ...testSeriesForm,
  //         [name]: file, // Save the file itself
  //         // posterPath: reader.result, // Save the preview URL
  //       });
  //       setPosterPreview(reader.result);
  //       // setPosterPreview({
  //       //   ...testSeriesForm,
  //       //   [name]: file, // Save the file itself
  //       //   posterPath: reader.result, // Save the preview URL
  //       // });
  //     };
  //     reader.readAsDataURL(file); // Create a preview URL
  //   }
  // }

  async function handleSubmitForm(e) {
    e.preventDefault();
    console.log("test series data =", testSeriesForm);

    // const formData = new FormData();
    // for (const key in testSeriesForm) {
    //   // console.log("key =", key, " and data =", data[key]);
    //   formData.append(key, testSeriesForm[key]);
    // }

    // formData.append("file", uploadExcelFile);

    const formData = new FormData();
    // const { posterFile, ...jsonData } = testSeriesForm;

    // formData.append("posterFile", posterFile);
    // formData.append("json", JSON.stringify(jsonData));
    if (posterFile) {
      formData.append("posterFile", posterFile);
    }
    let testSeriesFormData = testSeriesForm;
    if (testSeriesFormData.nsp === null) {
      delete testSeriesFormData.nsp;
    }
    if (testSeriesFormData.priceForDuration) {
      let priceDistributionsArray = [];
      testSeriesFormData.priceForDuration.forEach((element) => {
        if (element.nsp === null) {
          delete element.nsp;
        }
        priceDistributionsArray.push(element);
      });
      testSeriesFormData.priceForDuration = priceDistributionsArray;
    }
    formData.append("jsonData", JSON.stringify(testSeriesFormData));
    // formData.append("jsonData", JSON.stringify(testSeriesForm));
    formData.append("instituteId", JSON.stringify(loggedUserInstitute._id));

    console.log("uploading data =", testSeriesForm);
    if (!updateTestSeries) {
      // formData.append("posterFile", posterFile);
      const response = await postFormFetch(
        "testseries",
        formData,
        loggedUserInstitute._id
      );
      console.log("response while creating test series =", response);
      if (response.error) {
        toast.error(response.error);
      } else {
        toast.success(response.message);
        return setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } else {
      const response = await updateFormFetch(
        `testseries/dynamic/update/${currentTestSeries._id}`,
        formData,
        loggedUserInstitute._id
      );
      console.log("response", response);
      // handleToggleShowCreateTestSeries();
      if (response.error) {
        toast.error(response.error);
        console.log("error response =", response);
      } else {
        toast.success(response.message);
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      }
      // else {
      //   toast.error("Testseries update failed.");
      // }
    }
  }
  function handleCancelCreatingForm(e) {
    e.preventDefault();
    handleToggleShowCreateTestSeries();
  }

  function handleAddPriceDistribution(e) {
    e.preventDefault();
    setTestSeriesForm({
      ...testSeriesForm,
      priceForDuration: [
        ...testSeriesForm.priceForDuration,
        { duration: "", mrp: null, nsp: null },
        // { duration: "", mrp: null, nsp: null, expiryType: null },
      ],
    });
  }

  function handleRemovePriceDistribution(eli) {
    setTestSeriesForm((prevVal) => {
      const newVal = { ...prevVal };
      newVal.priceForDuration = newVal.priceForDuration.filter(
        (_, index) => index !== eli
      );
      return newVal;
    });
  }

  function handleFormPriceChange(e, eli) {
    const { name, value } = e.target;
    const specificName = name.split("-")[0];
    // const [specificName, elIndex] = name.split("-");
    setTestSeriesForm((prevVal) => {
      const newValPriceDuration = prevVal.priceForDuration;
      newValPriceDuration[eli][specificName] = value;
      return { ...prevVal, priceForDuration: newValPriceDuration };
    });
  }

  function handleAddSection(e) {
    e.preventDefault();
    setTestSeriesForm((prevVal) => {
      const newVal = { ...prevVal };
      newVal.sections = [...newVal.sections, ""];
      return newVal;
    });
  }

  function handleSectionNameInputChange(e) {
    const { name, value } = e.target;
    const elIndex = name.split("-")[1];
    // const [specificName, elIndex] = name.split("-");
    setTestSeriesForm((prevVal) => {
      const newVal = { ...prevVal };
      newVal.sections[elIndex] = value;
      return newVal;
    });
  }

  function handleRemoveSectionName(index) {
    if (testSeriesForm.sections.length > 1) {
      setTestSeriesForm((prevVal) => {
        const newVal = { ...prevVal };
        newVal.sections = newVal.sections.filter(
          (_, elIndex) => elIndex !== index
        );
        return newVal;
      });
    } else {
      toast.error("Minimum 1 section required.");
    }
  }

  return (
    <div className="height_full">
      <form className="height_full">
        <div className="createTestSeriesPopupFormDiv">
          <InputDiv
            value={testSeriesForm.name}
            onChangeFunc={handleFormInputChange}
            name={"name"}
            placeholder={"Name of TestSeries"}
            // label={"Name"}
          />
          <InputDiv
            value={testSeriesForm.publishedBy}
            onChangeFunc={() => {}}
            name={"publishedBy"}
            // label={"Published By"}
          />
          <InputDiv
            type="datetime-local"
            value={testSeriesForm.expiry}
            onChangeFunc={handleFormInputChange}
            name={"expiry"}
            label={"Expiry"}
          />
          <select
            name="isPublic"
            id="isPublic"
            className="inputSelect"
            // defaultValue="true"
            value={testSeriesForm.isPublic}
            onChange={handleFormInputChange}
          >
            <option value={true}>Public</option>
            <option value={false}>Private</option>
          </select>
          <InputDiv
            type="text"
            value={testSeriesForm.about}
            onChangeFunc={handleFormInputChange}
            name={"about"}
            placeholder={"About TestSeries"}
            // label={"About"}
          />
          <div style={{ width: "90%" }}>
            <JoditEditorComponent
              placeholder={"Description..."}
              value={testSeriesForm.description}
              onChange={(updatedValue) => {
                setTestSeriesForm({
                  ...testSeriesForm,
                  description: updatedValue,
                });
              }}
            />
          </div>

          <input
            style={{ width: "250px" }}
            type="file"
            id="posterFile"
            name="posterFile"
            onChange={handlePosterChange}
            // onChange={handleFormFileChange}
          />
          {posterPreview && (
            <img
              src={posterPreview}
              alt="Poster Preview."
              style={{ width: "225px", height: "140px", margin: "10px " }}
            />
          )}
          {/* <InputDiv
            type="text"
            value={testSeriesForm.poster}
            onChangeFunc={handleFormInputChange}
            name={"poster"}
            placeholder={"Poster"}
            // label={"Poster"}
          /> */}
          <label htmlFor="formSections" label="Paper Theme">
            <span>Exam Theme </span>
            <select
              className="createTestSeries_priceDistributionSelect"
              defaultValue={"OMR"}
              name={"theme"}
              value={testSeriesForm.theme}
              onChange={handleFormInputChange}
            >
              {/* <option value={null}>No Value</option> */}
              <option value="OMR">OMR</option>
              <option value="CBT">CBT</option>
            </select>
          </label>
          <InputDiv
            type="number"
            value={testSeriesForm.mrp}
            onChangeFunc={handleFormInputChange}
            name={"mrp"}
            placeholder="MRP"
          />
          <InputDiv
            type="number"
            value={testSeriesForm.nsp}
            onChangeFunc={handleFormInputChange}
            name={"nsp"}
            placeholder="NSP"
          />
          <div className="createTestSeries_testSeriesSectionsMainDiv">
            <div className="createTestSeries_testSeriesSectionsFirstDiv">
              <span>Sections</span>
              <button className="primaryBtn" onClick={handleAddSection}>
                Add New Section.
              </button>
            </div>
            <div className="createTestSeries_sectionsInnerMainDiv">
              {testSeriesForm.sections.map((sectionName, sectionIndex) => {
                return (
                  <div
                    key={sectionIndex}
                    className="createTestSeries_singleSectionDiv"
                  >
                    <span>{sectionIndex + 1}.</span>
                    <InputDiv
                      type="text"
                      value={testSeriesForm.sections[sectionIndex]}
                      onChangeFunc={handleSectionNameInputChange}
                      name={"section-" + sectionIndex}
                      placeholder={"Section Name"}
                    />
                    <Icon
                      icon={"material-symbols:delete"}
                      className="createTestSeries_priceDistributionDeleteOne"
                      onClick={() => handleRemoveSectionName(sectionIndex)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="createTestSeries_priceDistributionMainDiv">
            <div className="createTestSeries_priceDistributionFirstDiv">
              <span>Price Distribution</span>
              <button
                className="primaryBtn"
                onClick={handleAddPriceDistribution}
              >
                Add New Distribution.
              </button>
            </div>

            <div className="createTestSeries_priceDistributionEntriesDiv">
              {testSeriesForm.priceForDuration.map((el, eli) => {
                return (
                  <div
                    key={eli}
                    className="createTestSeries_priceDistributionInputDiv"
                  >
                    <span>{eli + 1}.</span>
                    <InputDiv
                      type="number"
                      value={testSeriesForm.priceForDuration[eli].duration}
                      onChangeFunc={(e) => handleFormPriceChange(e, eli)}
                      name={"duration-" + eli}
                      placeholder="DURATION"
                    />
                    <InputDiv
                      type="number"
                      value={testSeriesForm.priceForDuration[eli].mrp}
                      onChangeFunc={(e) => handleFormPriceChange(e, eli)}
                      name={"mrp-" + eli}
                      placeholder="MRP"
                    />
                    <InputDiv
                      type="number"
                      value={testSeriesForm.priceForDuration[eli].nsp}
                      onChangeFunc={(e) => handleFormPriceChange(e, eli)}
                      name={"nsp-" + eli}
                      placeholder="NSP"
                    />
                    {/* <select
                      className="createTestSeries_priceDistributionSelect"
                      defaultValue={null}
                      name={"expiryType-" + eli}
                      onChange={(e) => handleFormPriceChange(e, eli)}
                    >
                      <option value={null}>No Value</option>
                      <option value="TILL_EXAM">Till Exam</option>
                      <option value="TILL_DATE">Till Date</option>
                    </select> */}
                    <Icon
                      icon={"material-symbols:delete"}
                      className="createTestSeries_priceDistributionDeleteOne"
                      onClick={() => handleRemovePriceDistribution(eli)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <p className="submitBtnParagraphOfCreateTestSeriesPopup">
          <button className="primaryBtn" onClick={handleSubmitForm}>
            Submit Form
          </button>
          <button className="dangerBtn" onClick={handleCancelCreatingForm}>
            Cancel
          </button>
        </p>
      </form>
    </div>
  );
};

export default CreateTestSeriesPopup;

function InputDiv({
  value,
  onChangeFunc,
  name,
  placeholder = "",
  label,
  type = "text",
}) {
  return (
    <>
      <div className="createTestSeriesPopupFormDiv_singleInputDiv">
        {label && <label htmlFor="name">{label}</label>}
        <input
          style={{ width: "250px" }}
          type={type}
          id={name}
          name={name}
          value={value}
          onChange={onChangeFunc}
          placeholder={placeholder}
          className={
            type === "Date" || type === "datetime-local"
              ? "inputTypeDateClass"
              : ""
          }
        />
      </div>
    </>
  );
}
