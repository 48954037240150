import { useEffect, useState } from "react";
import Loader from "../../../components/common/loader/Loader";
import { postFetch } from "../../../functions/fetch/fetch.crud";
// import { handleAddPart } from "../functions";
// import { updateFetch } from "../../../functions/fetch/fetch.crud";

export function CurrentPartQuestionsPopup({
  questionIds,
  setShowCurrentPartQuestionsPopup,
  setGroupIndex,
  instituteId,
}) {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState(null);
  useEffect(() => {
    async function getQuestions() {
      const response = await postFetch(
        "question/get-by-ids",
        {
          ids: questionIds,
        },
        instituteId
        // { "x-database-name": instituteId }
      );
      setQuestions(response);
      setLoading(false);
    }
    if (loading) {
      getQuestions();
    }
  }, [instituteId, loading, questionIds]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div
          className="createTestSeries_singleSectionDiv"
          style={{
            zIndex: 10,
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backdropFilter: "blur(5px)",
            background: "rgba(0,0,0,0.4)",
            overflow: "auto",
          }}
        >
          <div
            style={{
              // border: "1px solid rgba(0,0,0,0.7)",
              padding: "20px",
              borderRadius: "5px",
              background: "white",
              width: "600px",
              // minWidth: "80%",
              maxHeight: "calc(100% - 130px)",
              // display: "flex",
              // gap: "10px",
              overflow: "auto",
            }}
          >
            <table
              style={{
                padding: "10px",
                border: "1px solid #dddddd",
                borderRadius: "5px",
                width: "90%",
                margin: "auto",
              }}
            >
              <thead>
                <tr>
                  <th style={{ borderBottom: "1px solid #dddddd" }}>No.</th>
                  <th style={{ borderBottom: "1px solid #dddddd" }}>
                    Question
                  </th>
                  <th style={{ borderBottom: "1px solid #dddddd" }}>Answer</th>
                </tr>
              </thead>
              <tbody>
                {questions.map((questionEl, questionEli) => {
                  return (
                    <tr key={questionEli}>
                      <td
                        style={{
                          borderBottom: "1px solid #dddddd",
                          textAlign: "center",
                        }}
                      >
                        {questionEli + 1}
                      </td>
                      <td
                        style={{
                          borderBottom: "1px solid #dddddd",
                          // textAlign: "center",
                          padding: "0 5px",
                        }}
                      >
                        {questionEl.questionType === "TEXT" ? (
                          `${questionEl.question}`
                        ) : (
                          <div
                            className="showRichText"
                            dangerouslySetInnerHTML={{
                              __html: questionEl.question,
                            }}
                          />
                        )}
                      </td>
                      <td
                        style={{
                          borderBottom: "1px solid #dddddd",
                          textAlign: "center",
                        }}
                      >
                        {questionEl.answer.join(",")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                // margin: "10px",
                padding: "15px 25px 0",
              }}
            >
              <button
                className="simpleBtn"
                onClick={() => {
                  setShowCurrentPartQuestionsPopup(undefined);
                  setGroupIndex(undefined);
                }}
              >
                Exit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
