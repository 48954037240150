import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
};

export const loggedUserSlice = createSlice({
  name: "institute",
  initialState,
  reducers: {
    setInstitute: (state, action) => {
      state.value = action.payload;
    },
    removeInstitute: (state) => {
      state.value = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setInstitute, removeInstitute } = loggedUserSlice.actions;

export default loggedUserSlice.reducer;
