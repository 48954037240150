import { createBrowserRouter } from "react-router-dom";
import Layout from "../layout/Layout";
// import OmrMainPage from "../pages/OmrPage/OmrMainPage";
import ProtectedRoute from "./ProtectedRoute";
// import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import GlobalLayout from "../layout/GlobalLayout";
import AllTestSeries from "../pages/AllTestSeries/AllTestSeries";
import TestSeries from "../pages/TestSeries/TestSeries";
import SingleTest from "../pages/SingleTest/SingleTest";
import { QuestionBank } from "../pages/QuestionBank/QuestionBank";
import Institutes from "../pages/Institutes/Institutes";

// export const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Layout />,
//     children: [
//       {
//         path: "/alltestseries",
//         element: (
//           // <ProtectedRoute requiredRole={["TEACHER", "FACULTY"]}>
//           <Home />
//           // </ProtectedRoute>
//         ),
//       },
//       { path: "/login", element: <Login /> },
//     ],
//   },
//   { path: "/omr", element: <OmrMainPage /> },
//   // { path: "/login", element: <Login /> },
// ]);

export const router = createBrowserRouter([
  {
    path: "/",
    element: <GlobalLayout />,
    children: [
      {
        path: "/",
        element: <Layout />,
        children: [
          {
            path: "/alltestseries",
            element: <AllTestSeries />,
          },
          {
            path: "/login",
            element: <Login />,
          },
          {
            path: "/institutes",
            element: (
              <ProtectedRoute requiredRole={["SUPER_ADMIN"]}>
                <Institutes />
              </ProtectedRoute>
            ),
          },
          {
            path: "/testseries",
            element: <TestSeries />,
          },
          {
            path: "/test",
            element: <SingleTest />,
          },
          {
            path: "/questionbank",
            element: <QuestionBank />,
          },
        ],
      },
      // {
      //   path: "/omr",
      //   element: <OmrMainPage />,
      // },
    ],
  },
]);
