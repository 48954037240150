import { useState } from "react";
import {
  postFetch,
  updateFetch,
  updateFormFetch,
} from "../../../functions/fetch/fetch.crud";
import { handleAddSection } from "../functions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export function AddSectionPopup({ setShowAddSectionPopup, currentTestSeries }) {
  // const loggedUser = useSelector((state) => state.loggedUser.value);
  const loggedUserInstitute = useSelector((state) => state.institute.value);
  // console.log("logged user =", loggedUser);
  const [sectionName, setSectionName] = useState("");
  return (
    <div
      className="createTestSeries_singleSectionDiv"
      style={{
        zIndex: 10,
        position: "fixed",
        // position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backdropFilter: "blur(5px)",
        background: "rgba(0,0,0,0.4)",
      }}
    >
      <div
        style={{
          // border: "1px solid rgba(0,0,0,0.7)",
          padding: "20px",
          borderRadius: "5px",
          background: "white",
        }}
      >
        {/* <span>{sectionIndex + 1}.</span> */}

        <input
          style={{ width: "250px", padding: "10px" }}
          type="text"
          id="section"
          name="section"
          value={sectionName}
          onChange={(e) => setSectionName(e.target.value)}
          placeholder="Enter Section Name"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "30px",
          }}
        >
          <button
            className="primaryBtn"
            onClick={async () => {
              if (sectionName.length > 0) {
                // console.log("updating...");
                const updatedCurrentTestSeries = {
                  // ...currentTestSeries,
                  sections: [...currentTestSeries.sections, sectionName],
                };
                console.log("sending =", updatedCurrentTestSeries);
                const formData = new FormData();
                formData.append(
                  "jsonData",
                  JSON.stringify(updatedCurrentTestSeries)
                );
                formData.append(
                  "instituteId",
                  JSON.stringify(loggedUserInstitute._id)
                );
                const response = await updateFormFetch(
                  // await postFetch(
                  `testseries/dynamic/update/${currentTestSeries._id}`,
                  formData,
                  // updatedCurrentTestSeries,
                  loggedUserInstitute._id
                  // loggedUser.instituteId
                  // { "x-database-name": loggedUser._id }
                );
                if (response.error) {
                  toast.error(response.error);
                } else {
                  toast.success("Test Series Updated.");
                  // console.log("response.", response);
                  return setTimeout(() => {
                    setShowAddSectionPopup(false);
                    window.location.reload();
                  }, 2500);
                }
                // setShowAddSectionPopup(false);
                // window.location.reload();

                // console.log("updated =", updatedCurrentTestSeries);
              } else {
                return toast.error("Please enter section name");
              }
            }}
          >
            Add
          </button>
          <button
            className="dangerBtn"
            onClick={() => handleAddSection(setShowAddSectionPopup, false)}
          >
            Cancel
          </button>
        </div>
        {/* <Icon
        icon={"material-symbols:delete"}
        className="createTestSeries_priceDistributionDeleteOne"
        // onClick={() => handleRemoveSectionName(sectionIndex)}
      /> */}
      </div>
    </div>
  );
}
