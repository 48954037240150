import "./joditEditor.css";
import React, { useMemo, useRef, useState } from "react";
import JoditEditor from "jodit-react";

export const JoditEditorComponent = ({
  placeholder,
  value = null,
  onChange,
}) => {
  const editor = useRef(null);
  const [content, setContent] = useState(value ?? "");
  //   const placeholder = "this is place holder.";

  const options = useMemo(
    () => [
      "bold",
      // "italic",
      // "|",
      "ul",
      "ol",
      "|",
      // "font",
      // "fontsize",
      // "|",
      "outdent",
      "indent",
      "align",
      "|",
      "hr",
      "|",
      // "fullsize",
      // "brush",
      // "|",
      "table",
      // "link",
      "|",
      "undo",
      "redo",
      "|",
      "image",
    ],
    []
  );
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: placeholder || "",
      defaultActionOnPaste: "insert_as_html",
      defaultLineHeight: 1.5,
      enter: "div",
      // options that we defined in above step.
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      statusbar: false,
      sizeLG: 900,
      sizeMD: 700,
      sizeSM: 400,
      toolbarAdaptive: false,
      uploader: {
        insertImageAsBase64URI: true, // set to false if you don't want to insert the image as a base64 URL
        url: "http://your-server.com/upload", // your upload endpoint
      },
      // filebrowser: {
      //   buttons: ["upload"], // Only allow the upload button
      // },
      // image: {
      //   openOnDblClick: false,
      //   editSrc: false, // Disable editing the image source
      // },
    }),
    [options, placeholder]
  );
  // const config = {
  //   placeholder: "start typing...",
  // };
  return (
    <div className="joditEditor">
      {/* <div style={{ margin: "20px" }} className="joditEditor"> */}
      {/* JoditEditor */}
      <JoditEditor
        // style={{ padding: "10px" }}
        // placeholder={placeholder || ""}
        ref={editor}
        value={content}
        config={config}
        // tabIndex={1} // tabIndex of textarea
        onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
        onChange={(newContent) => {
          if (onChange) {
            onChange(newContent);
          } else {
            setContent(newContent);
          }
        }}
      />
      {/* {content && content} */}
      {(content || value) && (
        <div>
          <p style={{ textDecoration: "underline", fontWeight: 700 }}>
            Sample Output :-
          </p>
          <div
            className="editor-content"
            // style={{ marginLeft: "50px" }}
            style={{ marginLeft: "10px" }}
            dangerouslySetInnerHTML={{ __html: value ? value : content }}
          />
        </div>
      )}
    </div>
  );
};
