import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children, requiredRole }) => {
  const navigate = useNavigate();
  const loggedUser = useSelector((state) => state.loggedUser.value);
  // const userRoles = ["TEACHER", "INSTITUTE_ADMIN"];
  if (loggedUser) {
    const userRoles = loggedUser.roles;
    // console.log("loggedUser =", loggedUser);
    const isUserPermitted = userRoles.some((userRole) =>
      requiredRole.includes(userRole)
    );

    if (!userRoles || !isUserPermitted) {
      return <Navigate to="/" />;
    }
    return children || <Outlet />;
  } else {
    return (
      <div>
        <button className="primaryBtn" onClick={() => navigate("/login")}>
          Click me to login.
        </button>
      </div>
    );
  }
};

export default ProtectedRoute;
