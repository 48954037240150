// import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleAddTest } from "../functions";
import { AddTestPopup } from "./AddTestPopup";
// import { postFetch } from "../../../functions/fetch/fetch.crud";

export function ShowSectionTestSeries({
  tests,
  sectionEl,
  sectionName,
  showAddTestPopup,
  setShowAddTestPopup,
  instituteId,
  offeringId,
}) {
  const navigate = useNavigate();
  // console.log("section name =", sectionName);
  // const [fetchedTests, setFetchedTests] = useState(null);

  // useEffect(() => {
  //   let effectLoaded = false;
  //   async function getTests() {
  //     const response = await postFetch("test/tests-of-testseries", {
  //       offeringId,
  //     });
  //     console.log("response while getting tests =", response.tests);
  //     setFetchedTests(response.tests);
  //   }

  //   if (!effectLoaded) {
  //     getTests();
  //   }

  //   return () => (effectLoaded = true);
  // }, [offeringId]);

  // console.log("tests =", tests);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "end", padding: "5px" }}>
        <button
          className="simpleBtn"
          // className="primaryBtn"
          onClick={() => handleAddTest(setShowAddTestPopup)}
        >
          Add Test
        </button>
      </div>
      {showAddTestPopup && (
        <AddTestPopup
          setShowAddTestPopup={setShowAddTestPopup}
          instituteId={instituteId}
          offeringId={offeringId}
          sectionName={sectionName}
        />
      )}
      {tests && sectionEl >= 0 && (
        <div className="allTestsUnderSingleSectionMainDiv-singleTestSeriesAllTests">
          {
            // tests
            //   .filter((el) => {
            //     return el.sectionName === sectionName;
            //   })
            tests?.map((testEl, testEli) => {
              // console.log("tests single elememt =", testEl);
              return (
                <div
                  key={testEli}
                  // key={testEli + "-" + testEli}
                  className="singleTestMainDiv-singleTestSeriesAllTests"
                >
                  <div className="singleTestTopDiv-singleTestSeriesAllTests">
                    <span className="testName-singleTestSeriesAllTests">
                      {testEl.name}
                    </span>
                    {/* for below button, purchase and login verification is remaining. */}
                    <div className="rightSideOfTestName-singleTestSeriesAllTests">
                      {/* <div
                        className={`${
                          testEl.isFree
                            ? "free-giveTestButton-singleTestSeriesAllTests"
                            : "giveTestButton-singleTestSeriesAllTests"
                        }`}
                        // onClick={() =>
                        //   handleNavigateToPath(testEl.offeringId, testEl.testId)
                        // }
                      >
                        {testEl.isFree ? "Attempt For Free" : "Paid Test"}
                      </div>
                      <span style={{ fontSize: "10px", marginRight: "2px" }}>
                        Remaining Attempts : 1/{testEl.numberOfAttempts}
                      </span> */}
                      <span
                        // className="free-giveTestButton-singleTestSeriesAllTests"
                        className="simpleBtn"
                        onClick={() => navigate(`/test?id=${testEl._id}`)}
                      >
                        View More
                      </span>
                    </div>
                  </div>
                  <div className="singleTestSecondDiv-singleTestSeriesAllTests">
                    Questions : {testEl.numberOfQuestions}
                  </div>
                </div>
              );
            })
          }
        </div>
      )}
    </>
  );
}
