import React from "react";
import { PriceDistributionHeader } from "./PriceDistributionHeader";
import { PriceDistributionPrices } from "./PriceDistributionPrices";

export const InformationDetails = ({ currentTestSeries }) => {
  return (
    <div className="testSeriesPage_2s_detailsDiv">
      <div>
        <span style={{ fontWeight: "600" }}>About : </span>
        {currentTestSeries.about}
      </div>
      <div>
        <span style={{ fontWeight: "600" }}>Description : </span>
        {/* {currentTestSeries.description} */}
        <div
          className="showRichText"
          dangerouslySetInnerHTML={{ __html: currentTestSeries.description }}
        />
      </div>
      <div>
        {/* <div key={111}> */}
        {/* <div key={"details"}> */}
        <p style={{ fontWeight: "600" }}>Price Distribution : </p>
        <div
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            overflowX: "auto",
          }}
        >
          {currentTestSeries.priceForDuration?.length > 0 && (
            <PriceDistributionHeader />
          )}
          {currentTestSeries.priceForDuration?.map((elPrice, eliPrice) => {
            // const uniqueKey =
            //   elPrice._id || `price-${elPrice.duration}-${eliPrice}`;
            // console.log("unique key =", uniqueKey);

            return (
              <PriceDistributionPrices
                key={eliPrice}
                elPrice={elPrice}
                eliPrice={eliPrice}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
