import React, { useState } from "react";
import {
  getExcelFetch,
  postExcelFetch,
} from "../../../functions/fetch/fetch.crud";
import { toast } from "react-toastify";
// import axios from "axios";

export const UploadExcel = ({ setUploadExcel, instituteId }) => {
  const [uploadExcelFile, setUploadExcelFile] = useState(null);

  const handleFileChange = (e) => {
    setUploadExcelFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!uploadExcelFile) {
      toast.error("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("file", uploadExcelFile);

    try {
      // await postExcelFetch(
      const response = await postExcelFetch(
        "question/bulkupload",
        formData,
        instituteId
      );
      // console.log("response =", response);
      if (response.errors.length > 0) {
      } else {
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error(error.message);
    }
  };
  return (
    <div
      style={{
        zIndex: 10,
        position: "fixed",
        // position: "absolute",
        top: "60px",
        left: 0,
        width: "100vw",
        height: "calc(100vh - 60px)",
        backdropFilter: "blur(5px)",
        background: "rgba(0,0,0,0.4)",
        display: "flex",
        flexWrap: "wrap",
        alignContent: "center",
      }}
      //   onClick={() => setAddQuestionPopup(false)}
    >
      <div
        style={{
          backgroundColor: "white",
          width: "90%",
          margin: "0 5%",
          maxHeight: "80%",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          padding: "10px",
          overflowY: "auto",
          gap: "10px",
        }}
      >
        <div>
          <button
            className="simpleBtn"
            onClick={async () => {
              console.log("excel downloading.");

              await getExcelFetch("question/bulkupload", instituteId, 10);
            }}
            style={{ marginRight: "10px" }}
          >
            Download Formated Excel
          </button>
        </div>
        <hr />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignContent: "center",
            gap: "10px",
          }}
        >
          <span>Upload Excel Here.</span>
          <input
            type="file"
            name="file"
            id="file"
            onChange={handleFileChange}
            // onChange={(e) => {
            //   setUploadExcelFile(e.target.files[0]);
            // }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <button className="primaryBtn" onClick={handleUpload}>
            Upload Excel
          </button>
          <button className="dangerBtn" onClick={() => setUploadExcel(false)}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
