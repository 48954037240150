import { configureStore } from "@reduxjs/toolkit";
import logInReducer from "./login/Slices";
import loggedUserReducer from "./loggedUser/Slices";
import InstituteReducer from "./Institute/Slices";

export const store = configureStore({
  reducer: {
    logIn: logInReducer,
    loggedUser: loggedUserReducer,
    institute: InstituteReducer,
  },
});
