// import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/common/header/Header";
import { useLocation } from "react-router-dom";
import Home from "../pages/Home/Home";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logIn } from "../redux/loggedUser/Slices";

const Layout = () => {
  const location = useLocation();
  const [localUserFetched, setLocalUserFetched] = useState(false);
  const loggedUser = useSelector((state) => state.loggedUser.value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loggedUser) {
      if (!localUserFetched) {
        async function findLoggedInUser() {
          try {
            const loggedInUserInLocalStorage = localStorage.getItem("token");
            if (loggedInUserInLocalStorage) {
              const loggedInUser = JSON.parse(loggedInUserInLocalStorage);
              dispatch(logIn(loggedInUser));
              // console.log("user is created.", loggedUser);
              // dispatch({ type: 'SET_LOGGED_USER', value: loggedInUser })
            }
          } catch (error) {}
        }
        findLoggedInUser();
        setLocalUserFetched(true);
      }
    }
    //  else {
    //   console.log("user is created.", loggedUser);
    // }
  }, [dispatch, localUserFetched, loggedUser]);

  return (
    <>
      <Header />
      <main>
        <Outlet /> {/* Placeholder for route content */}
      </main>
      {location.pathname === "/" && <Home />}
    </>
  );
};

export default Layout;
