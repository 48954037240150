import React, { useState } from "react";
import { postFetch } from "../../../functions/fetch/fetch.crud";
import { JoditEditorComponent } from "../../../components/richtext/JoditEditor/JoditEditor";
// import JoditEditor from "jodit-react";

export const AddQuestionPopup = ({ setAddQuestionPopup, instituteId }) => {
  const optionNames = ["a", "b", "c", "d"];
  const optionsArray = [];
  optionNames.forEach((optionName) => {
    optionsArray.push({
      optionName: optionName,
      optionString: "",
      optionType: "TEXT",
    });
  });
  const [questionForm, setQuestionForm] = useState({
    question: "",
    questionType: "TEXT",
    options: optionsArray,
    answer: [],
    instituteId,
  });

  function handleStarQuestion() {
    setQuestionForm({ ...questionForm, answer: ["star"] });
  }

  async function handleFormSubmit(e) {
    e.preventDefault();
    console.log("form data =", questionForm);
    // console.log('')
    const response = await postFetch("question", questionForm, instituteId);
    // console.log("response from create question =", response);
    if (response.error) {
      alert(response.error);
    } else {
      setAddQuestionPopup(false);
      window.location.reload();
    }
  }
  return (
    <div
      style={{
        zIndex: 10,
        position: "fixed",
        // position: "absolute",
        top: "60px",
        left: 0,
        width: "100vw",
        height: "calc(100vh - 60px)",
        backdropFilter: "blur(5px)",
        background: "rgba(0,0,0,0.4)",
        display: "flex",
        flexWrap: "wrap",
        alignContent: "center",
      }}
      //   onClick={() => setAddQuestionPopup(false)}
    >
      <div
        style={{
          backgroundColor: "white",
          width: "96%",
          margin: "0 2%",
          height: "90%",
          borderRadius: "5px",
          overflow: "auto",
        }}
      >
        <h1 style={{ textAlign: "center", marginTop: "5px" }}>Add Question</h1>
        <hr />
        <form
          //   action="javascript:void(0)"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            alignContent: "center",
            padding: "10px",
            gap: "10px",
          }}
          onSubmit={(e) => e.preventDefault()}
          //   onSubmit={handleFormSubmit}
        >
          <div>
            <label htmlFor="questionType" style={{ marginRight: "10px" }}>
              Question
              {/* Question Type */}
            </label>
            <select
              className="selectInQuestion"
              name="questionType"
              id="questionType"
              value={questionForm.questinType}
              onChange={(e) => {
                setQuestionForm({
                  ...questionForm,
                  questionType: e.target.value,
                });
              }}
            >
              <option value="TEXT">SIMPLE</option>
              <option value="RICH_TEXT">RICH TEXT</option>
            </select>
          </div>
          {questionForm.questionType === "TEXT" ? (
            <input
              type="text"
              name="question"
              id="question"
              placeholder="Question"
              value={questionForm.question}
              style={{
                //   width: "250px",
                width: "90%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #dddddd",
              }}
              onChange={(e) =>
                setQuestionForm({ ...questionForm, question: e.target.value })
              }
            />
          ) : (
            <div
              style={{
                //   width: "250px",
                width: "100%",
                // padding: "10px",
                // borderRadius: "5px",
                // border: "1px solid #dddddd",
              }}
            >
              <JoditEditorComponent
                value={questionForm.question}
                onChange={(updatedValue) =>
                  setQuestionForm({ ...questionForm, question: updatedValue })
                }
              />
            </div>
          )}
          <div style={{ width: "90%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Options</span>
              {/* <button
                className="simpleBtn"
                onClick={() => {
                  if (questionForm.options.length < optionNames.length) {
                    setQuestionForm({
                      ...questionForm,
                      options: [
                        ...questionForm.options,
                        {
                          optionName: optionNames[questionForm.options?.length],
                          optionString: "",
                        },
                      ],
                    });
                  } else {
                    alert("Maximum options allowed = " + optionNames.length);
                  }
                }}
              >
                Add Option
              </button> */}
            </div>
          </div>
          <div
            style={{
              width: "90%",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              justifyContent: "space-evenly",
              gap: "10px",
              alignContent: "center",
            }}
          >
            {questionForm.options.map((optionEl, optionEli) => {
              return (
                <div
                  key={optionEli}
                  style={{
                    width: "100%",
                  }}
                >
                  <label
                    htmlFor={`${optionEl.optionName}-${optionEli}`}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      gap: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    <span style={{ margin: "auto 3px", width: "79px" }}>
                      {optionEl.optionName}

                      {/* Option : {optionEl.optionName} */}
                      <select
                        className="selectInOption"
                        name={`${optionEl.optionName}-${optionEli}-select`}
                        id={`${optionEl.optionName}-${optionEli}-select`}
                        value={questionForm.options[optionEli].optionType}
                        onChange={(e) => {
                          setQuestionForm((prevForm) => ({
                            ...prevForm,
                            options: prevForm.options.map((option, index) =>
                              index === optionEli
                                ? { ...option, optionType: e.target.value }
                                : option
                            ),
                          }));
                        }}
                      >
                        <option value="TEXT">SIMPLE</option>
                        <option value="RICH_TEXT">RICH TEXT</option>
                      </select>
                    </span>
                    {optionEl.optionType === "TEXT" ? (
                      <input
                        type="text"
                        style={{
                          width: "calc(100% - 231px)",
                          minWidth: "250px",
                          padding: "10px",
                        }}
                        name={`${optionEl.optionName}-${optionEli}`}
                        id={`${optionEl.optionName}-${optionEli}`}
                        value={optionEl.optionString}
                        onChange={(e) => {
                          const newOptions = questionForm.options;
                          newOptions[optionEli].optionString = e.target.value;
                          setQuestionForm({
                            ...questionForm,
                            options: newOptions,
                          });
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          width: "calc(100% - 231px)",
                          minWidth: "250px",
                          // height:
                        }}
                      >
                        <JoditEditorComponent
                          value={optionEl.optionString}
                          onChange={(updatedValue) => {
                            const newOptions = questionForm.options;
                            newOptions[optionEli].optionString = updatedValue;
                            setQuestionForm({
                              ...questionForm,
                              options: newOptions,
                            });
                          }}
                        />
                      </div>
                    )}
                    <button
                      className="simpleBtn"
                      style={{ width: "126px" }}
                      onClick={() => {
                        let ans = questionForm.answer;
                        if (questionForm.answer.includes("star")) {
                          ans = [];
                        }
                        if (
                          questionForm.answer?.includes(optionEl.optionName)
                        ) {
                          ans = questionForm.answer.filter((ans) => {
                            return ans !== optionEl.optionName;
                          });
                        } else {
                          ans = [...ans, optionEl.optionName];
                        }
                        setQuestionForm({ ...questionForm, answer: ans });
                        return;
                      }}
                    >
                      {questionForm.answer?.includes(optionEl.optionName)
                        ? "Remove Answer"
                        : "Add To Answer"}
                    </button>
                    {/* <label
                      htmlFor=""
                      style={{
                        margin: "auto",
                        display: "flex",
                        // justifyContent: "center",
                        alignContent: "center",
                        gap: "10px",
                      }}
                    >
                      Add to Answer
                      <input type="radio" />
                    </label> */}
                  </label>
                </div>
              );
            })}
          </div>
          {/* <select
            name="answer"
            id="answer"
            style={{
              width: "250px",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #dddddd",
            }}
            onChange={(e) => {
              const newSelections = Array.from(e.target.selectedOptions).map(
                (option) => option.value
              );
              setQuestionForm({ ...questionForm, answer: newSelections });
            }}
          >
            {questionForm.options?.map((optionEl, optionEli) => {
              return (
                <option key={optionEli} value={optionEl.optionName}>
                  {optionEl.optionName}
                </option>
              );
            })}
          </select>
           */}

          <div style={{ minWidth: "20%" }}>
            <span>
              Answers :{" "}
              <span style={{ textTransform: "uppercase" }}>
                {questionForm.answer.length > 0
                  ? questionForm.answer.join(", ")
                  : "No answer is selected."}
              </span>
            </span>

            <button className="simpleBtn mx10" onClick={handleStarQuestion}>
              Star Question
            </button>
          </div>

          {/* <input
            type="select"
            name="answer"
            id="answer"
            placeholder="Answer"
            style={{
              width: "250px",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #dddddd",
            }}
          /> */}
          <button
            className="primaryBtn"
            onClick={handleFormSubmit}
            style={{ width: "90%" }}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
