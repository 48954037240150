import { useLocation } from "react-router-dom";
import "./testSeries.css";
import React, { useEffect, useState } from "react";
// import axios from "axios";
import Loader from "../../components/common/loader/Loader";
import {
  getFetch,
  postFetch,
  updateFetch,
  updateFormFetch,
} from "../../functions/fetch/fetch.crud";
import { Icon } from "@iconify/react/dist/iconify.js";
import { handleAddSection, handleSectionChange } from "./functions";
import { AddSectionPopup } from "./components/AddSectionPopup";
import { ShowSectionTestSeries } from "./components/ShowSectionTestSeries";
import { BasicDetailsSection } from "./components/BasicDetailsSection";
// import { PriceDistributionHeader } from "./components/PriceDistributionHeader";
// import { PriceDistributionPrices } from "./components/PriceDistributionPrices";
import { InformationDetails } from "./components/InformationDetails";
import { useSelector } from "react-redux";
import CreateTestSeriesPopup from "../AllTestSeries/createTestSeries/CreateTestSeries.popup";
import { toast } from "react-toastify";

const TestSeries = () => {
  const location = useLocation();
  const loggedUser = useSelector((state) => state.loggedUser.value);
  const loggedUserInstitute = useSelector((state) => state.institute.value);
  const [loading, setLoading] = useState(true);
  const [currentTestSeries, setCurrentTestSeries] = useState(null);
  const [expiryDate, setExpiryDate] = useState();
  const [currentTestsIndex, setCurrentTestsIndex] = useState(0);
  const [showAddSectionPopup, setShowAddSectionPopup] = useState(false);
  const [showAddTestPopup, setShowAddTestPopup] = useState(false);

  const moreInformationContent = ["Details", "Tests"];
  const [currentInformationContent, setCurrentInformationContent] = useState(0);
  const [updateTestSeries, setUpdateTestSeries] = useState(false);

  // function handleSectionChange(index) {
  //   // console.log("changing section index to =", index);
  //   setCurrentTestsIndex(index);
  // }
  async function handleRemoveSection(index) {
    const updatedSections = currentTestSeries.sections.filter(
      (el, eli) => eli !== index
    );

    // console.log("updated sections =", updatedSections);
    const formData = new FormData();
    formData.append("jsonData", JSON.stringify({ sections: updatedSections }));
    formData.append("instituteId", JSON.stringify(loggedUserInstitute._id));
    const response = await updateFormFetch(
      `testseries/dynamic/update/${currentTestSeries._id}`,
      formData,
      // { sections: updatedSections },
      loggedUserInstitute._id
      // loggedUser.instituteId
      // { "x-database-name": loggedUser._id }
    );
    console.log("response =", response);
    if (response.error) {
      toast.error(response.error);
    } else {
      toast.success("Test Series Updated.");
      return setTimeout(() => {
        window.location.reload();
      }, 2500);
    }
  }

  function handleToggleShowCreateTestSeries() {
    setUpdateTestSeries(!updateTestSeries);
  }

  useEffect(() => {
    const SearchParams = new URLSearchParams(location.search);
    if (loading && SearchParams.get("id") && loggedUser) {
      async function getTestSeries() {
        // const token = localStorage.getItem("token");
        // const response = await axios.get(
        //   `${
        //     process.env.REACT_APP_API_URL
        //   }testseries/dynamic/${SearchParams.get("id")}`,
        //   {
        //     headers: { token },
        //   }
        // );
        // const response = await postFetch("test/tests-of-testseries", {
        //   offeringId: SearchParams.get("id"),
        // });
        const response = await getFetch(
          `testseries/dynamic/${SearchParams.get("id")}`,
          loggedUserInstitute._id
          // loggedUser.instituteId
          // { "x-database-name": loggedUser._id }
          // { "x-database-name": SearchParams.get("institute") }
        );
        console.log("response =", response);
        setLoading(false);
        if (response) {
          const dateData = response.expiry;
          setExpiryDate(new Date(dateData));
          setCurrentTestSeries(response);
        }
        // }, 3000000);
      }
      getTestSeries();
    }
  }, [loading, location, loggedUser, loggedUserInstitute]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <BasicDetailsSection
            currentTestSeries={currentTestSeries}
            expiryDate={expiryDate}
            instituteId={loggedUserInstitute._id}
            handleToggleShowCreateTestSeries={handleToggleShowCreateTestSeries}
          />
          {updateTestSeries && (
            <div className="createTestSeriesPopup">
              <CreateTestSeriesPopup
                handleToggleShowCreateTestSeries={
                  handleToggleShowCreateTestSeries
                }
                currentTestSeries={currentTestSeries}
                updateTestSeries={true}
              />
            </div>
          )}

          <section className="testSeriesPage_secondSection">
            {/* <p className="testSeriesPage_2s_1p">Tests By Sections :-</p> */}
            <div className="testSeriesPage_2s_titleDiv">
              {moreInformationContent.map((el, eli) => {
                return (
                  <div
                    key={eli}
                    className={`testSeriesPage_2s_singleTitle ${
                      currentInformationContent === eli
                        ? "singleTitle_isActive"
                        : ""
                    }`}
                    onClick={() => setCurrentInformationContent(eli)}
                  >
                    {el}
                  </div>
                );
              })}
              {/* <div className="testSeriesPage_2s_singleTitle">Details</div>
              <div className="testSeriesPage_2s_singleTitle">Tests</div> */}
            </div>
            {moreInformationContent[currentInformationContent] === "Details" ? (
              <InformationDetails currentTestSeries={currentTestSeries} />
            ) : moreInformationContent[currentInformationContent] ===
              "Tests" ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    margin: "10px ",
                  }}
                >
                  <button
                    className="simpleBtn"
                    // className="primaryBtn"
                    onClick={() => handleAddSection(setShowAddSectionPopup)}
                  >
                    Add Section
                  </button>
                  {showAddSectionPopup && (
                    <AddSectionPopup
                      setShowAddSectionPopup={setShowAddSectionPopup}
                      currentTestSeries={currentTestSeries}
                    />
                  )}
                </div>
                {currentTestSeries?.sections?.map((elSection, eliSection) => {
                  // console.log("elsection =", elSection);
                  // console.log("id =", elSection._id);
                  return (
                    <div key={eliSection}>
                      {/* <div key={`${elSection}-${eliSection}`}> */}
                      <div
                        className="testSeriesPage_2s_sectionDiv"
                        style={{
                          // margin: "10px",
                          padding: "10px",
                          width: "100%",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          // backgroundColor: "#EEFFDE",

                          borderRadius: "5px",
                          ...(currentTestsIndex === eliSection
                            ? {
                                // boxShadow:
                                //   "inset 5px 5px 10px rgba(0, 128, 0, 0.1),   inset -5px -5px 10px rgba(0, 128, 0, 0.1)",
                                margin: "10px auto",
                                padding: "10px 20px",
                                border: "1px solid rgba(0,0,0,0.8)",
                                backgroundColor: "rgba(225, 225, 225, 0.4)",
                              }
                            : // ? { backgroundColor: "#A1FF3D" }
                              { border: "1px solid rgba(0,0,0,0.2)" }),
                          // { backgroundColor: "#EEFFDE" }),
                        }}
                        onClick={() => {
                          handleSectionChange(eliSection, setCurrentTestsIndex);
                        }}
                      >
                        <span
                          style={{
                            textTransform: "uppercase",
                            ...(currentTestsIndex === eliSection
                              ? {
                                  fontWeight: "800",
                                  scale: "0.9",
                                  // textDecoration: "underline wavy blue 5px",
                                }
                              : { fontWeight: "600" }),
                          }}
                        >
                          {eliSection + 1}.{" "}
                          <span
                            style={{
                              marginLeft: "10px",
                              ...(currentTestsIndex === eliSection
                                ? {
                                    // fontWeight: "800",
                                    // textDecoration:
                                    //   "underline dotted green 2px",
                                  }
                                : {}),
                            }}
                          >
                            {elSection}
                          </span>
                        </span>
                        <Icon
                          icon={"material-symbols:delete"}
                          // className="createTestSeries_priceDistributionDeleteOne"
                          style={{
                            color: "rgba(255,0,0,0.7)",
                            height: "20px",
                            width: "20px",
                          }}
                          onClick={() => {
                            const array = currentTestSeries?.tests.filter(
                              (el) => {
                                return el.sectionName === elSection;
                              }
                            );
                            console.log("array =", array);
                            if (array.length > 0) {
                              return toast.error(
                                "Section is not Empty. Can't delete section."
                              );
                            } else {
                              if (
                                window.confirm(
                                  "Are you sure you want to delete section?"
                                )
                              ) {
                                console.log("deleting section.");
                                handleRemoveSection(eliSection);
                              }
                            }
                          }}
                        />
                      </div>
                      {currentTestsIndex === eliSection && (
                        <ShowSectionTestSeries
                          tests={currentTestSeries?.tests.filter((el) => {
                            return el.sectionName === elSection;
                          })}
                          sectionEl={eliSection}
                          sectionName={elSection}
                          showAddTestPopup={showAddTestPopup}
                          setShowAddTestPopup={setShowAddTestPopup}
                          instituteId={currentTestSeries?.instituteId}
                          offeringId={currentTestSeries?._id}
                        />
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </section>
        </div>
      )}
    </>
  );
};

export default TestSeries;
