import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;

export const getHeaders = (instituteId, headerData) => {
  const token = localStorage.getItem("token");
  return {
    ...(instituteId && { "x-database-name": instituteId }),
    ...(headerData && headerData),
    "Content-Type": "application/json",
    token,
    // Authorization: `Bearer ${token}`, // Or whatever header format you need
  };
};

export const getFormHeaders = (instituteId, headerData) => {
  const token = localStorage.getItem("token");
  return {
    ...(instituteId && { "x-database-name": instituteId }),
    ...(headerData && headerData),
    token, // Do not set Content-Type here, let the browser handle it
  };
};

export async function getFetch(url, instituteId = null, headerData = null) {
  const response = await fetch(`${API_URL}${url}`, {
    method: "GET",
    headers: getHeaders(instituteId, headerData),
    // body: JSON.stringify(data),
  });
  const result = await response.json();
  return result;
}
// const myHeaders = new Headers();
// myHeaders.append("Content-Type", "application/json");

export async function postFetch(
  url,
  data,
  instituteId = null,
  jsonData = false,
  headerData = null
) {
  try {
    // console.log("data =", data);
    // export async function postFetch(url, data, errorText = false) {
    console.log("post request url =", `${API_URL}${url}`);
    const response = await fetch(`${API_URL}${url}`, {
      method: "POST",
      headers: {
        ...getHeaders(instituteId, headerData),
        ...(jsonData && { "Content-Type": "application/json" }),
      },
      body: JSON.stringify(data),
    });
    console.log("main response in post request =", response);
    // console.log("main response in post request json =", await response.json());
    if (response.status !== 500) {
      const res = await response.json();
      // console.log("res =", res);

      return res;
    }

    return { error: "Internal Server Error." };
  } catch (error) {
    console.error("Error in postFetch:", error);
    // alert(error);
    return { error };
    // return { error: "An error occurred" };
  }
}

export async function postFormFetch(
  url,
  data,
  instituteId = null,
  headerData = null
) {
  const response = await fetch(`${API_URL}${url}`, {
    method: "POST",
    headers: getFormHeaders(instituteId, headerData),
    body: data,
    // body: formData,
  });
  console.log("main response in post request =", response);
  if (response.status !== 500) {
    const res = await response.json();
    console.log("res =", res);
    return res;
  }
  return { error: "Internal Server Error." };
}

export async function postExcelFetch(
  url,
  data,
  instituteId = null,
  headerData = null
) {
  // console.log("instituteid =", instituteId);
  // export async function postFetch(url, data, errorText = false) {
  const token = localStorage.getItem("token");
  const response = await fetch(`${API_URL}${url}`, {
    method: "POST",
    headers: {
      "x-database-name": instituteId,

      // "Content-Type": "multipart/form-data",
      token,
    },
    // headers: getHeaders(instituteId, headerData),
    // body: JSON.stringify(data),
    body: data,
  });
  // console.log("main response in post request =", response);
  if (response.status !== 500) {
    const jsonResponse = await response.json();
    // toast.success(jsonResponse.message);
    if (response.status === 400) {
      toast.error(jsonResponse.error);
    }
    if (response.status === 201) {
      // console.log("json response =", jsonResponse);
      if (jsonResponse.errors.length > 0) {
        toast.error("Got some errors while uploading questions.");
        return jsonResponse;
      } else {
        toast.success(jsonResponse.message);
        // toast.success("Questions uploaded successfully.");
        return jsonResponse;
      }
    }

    // return response.json();
  }
  // if (response.ok) return response.json();
  // alert(errorText ? errorText : "Error While Creating.");
  toast.error("Internal Server Error.");
  return { error: "Internal Server Error." };
}

export async function updateFetch(
  url,
  data,
  instituteId = null,
  headerData = null
) {
  const response = await fetch(`${API_URL}${url}`, {
    method: "PATCH",
    headers: getHeaders(instituteId, headerData),
    body: JSON.stringify(data),
  });
  if (response.status !== 500) {
    const res = await response.json();
    return res;
  }
  return { error: "Internal Server Error." };
}
export async function updateFormFetch(
  url,
  data,
  instituteId = null,
  headerData = null
) {
  const response = await fetch(`${API_URL}${url}`, {
    method: "PATCH",
    headers: getFormHeaders(instituteId, headerData),
    body: data,
  });
  if (response.status !== 500) {
    const res = await response.json();
    return res;
  }
  return { error: "Internal Server Error." };
}

export async function getExcelFetch(
  url,
  instituteId = null,
  questions = 100,
  headerData = null
) {
  const response = await fetch(`${API_URL}${url}?questions=${questions}`, {
    method: "GET",
    headers: getHeaders(instituteId),
    // body: JSON.stringify(data),
  });

  // Check if the response is successful
  if (!response.ok) {
    throw new Error("Failed to download file");
  }

  // Convert response to Blob
  const blob = await response.blob();

  // Create a URL for the Blob
  const functionalUrl = window.URL.createObjectURL(blob);

  // Create a link element to download the file
  const a = document.createElement("a");
  a.href = functionalUrl;
  a.download = "Question Bulk Upload.xlsx"; // Set the file name
  document.body.appendChild(a);
  a.click();

  // Clean up and remove the link
  a.remove();
  window.URL.revokeObjectURL(functionalUrl);
  return;
}

// // Update (PUT) function
// async function updateData(url, data) {
//   const response = await fetch(`${API_URL}${url}`, {
//     method: "PUT",
//     headers: getHeaders(),
//     body: JSON.stringify(data),
//   });
//   return response.json();
// }

// // Delete (DELETE) function
// async function deleteData(url) {
//   const response = await fetch(`${API_URL}${url}`, {
//     method: "DELETE",
//     headers: getHeaders(),
//   });
//   return response.json();
// }
