import React from "react";

export const PriceDistributionPrices = ({ elPrice, eliPrice }) => {
  return (
    <div
      key={eliPrice}
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        minWidth: "100%",
        width: "400px",
        height: "40px",
        marginTop: "10px",
        borderBottom: "2px solid rgba(0, 0, 0, 0.2)",
      }}
    >
      <span
        style={{
          fontWeight: "600",
          flex: "4",
          textAlign: "center",
        }}
      >
        {elPrice.duration} Days : {/* For {elPrice.duration} Days :{" "} */}
      </span>

      {elPrice.nsp ? (
        <span style={{ flex: "6", textAlign: "center" }}>
          <span style={{ color: "green" }}>{elPrice.nsp} ₹</span>
          <span
            style={{
              // color: "green",
              textDecoration: "line-through",
              fontSize: "14px",
              marginLeft: "5px",
              fontWeight: "400",
            }}
          >
            {elPrice.mrp} ₹
          </span>
          <span className="priceSavePercentage" style={{ marginLeft: "10px" }}>
            ( Save Flat{" "}
            {(((elPrice.mrp - elPrice.nsp) / elPrice.mrp) * 100).toFixed(2)}% )
          </span>
        </span>
      ) : (
        <span style={{ color: "green" }}>{elPrice.mrp} ₹</span>
      )}
      {/* <span style={{ color: "green" }}>
                              {elPrice.mrp} ₹
                            </span> */}
    </div>
  );
};
