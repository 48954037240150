import { useEffect, useState } from "react";
import {
  getFetch,
  postFetch,
  updateFetch,
} from "../../../functions/fetch/fetch.crud";
import Loader from "../../../components/common/loader/Loader";
import { toast } from "react-toastify";
// import { handleAddPart } from "../functions";
// import { updateFetch } from "../../../functions/fetch/fetch.crud";

export function ManageQuestionsPopup({
  setShowManageQuestionsPopup,
  maximumAllowedQuestions,
  setMaximumAllowedQuestions,
  test,
  // testId,
  // groups,
  groupIndex,
  setGroupIndex,
  instituteId,
}) {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [updateSelectedQuestions, setUpdateSelectedQuestions] = useState([]);
  const size = 10;

  useEffect(() => {
    if (loading) {
      async function getPaginagedQuestions() {
        const response = await getFetch(
          `question/paginate?page=${page}&size=${size}`,
          instituteId
          // { "x-database-name": instituteId }
        );
        // console.log("response =", response);
        setQuestions(response);
        setLoading(false);
      }
      async function getSelectedQuestions() {
        const response = await postFetch(
          `question/get-by-ids`,
          {
            ids: test.groups[groupIndex].questions,
          },
          instituteId
          // { "x-database-name": instituteId }
        );
        console.log("response =", response);
        setSelectedQuestions(response);
      }

      async function stopLoading() {
        // console.log('selected questins =', )
        await getPaginagedQuestions();
        await getSelectedQuestions();

        setLoading(false);
      }
      stopLoading();
    }
  }, [groupIndex, instituteId, loading, page, test]);

  async function handleChangePage(pageNumber) {
    if (pageNumber <= 0) {
      toast.info("This is first page.");
      return;
    }
    const response = await getFetch(
      `question/paginate?page=${pageNumber}&size=${size}`,
      instituteId
      // { "x-database-name": instituteId }
    );
    if (response.error) {
      toast.error(response.message);
    } else if (response.length === 0) {
      toast.info("This is last page.");
    } else {
      setPage(pageNumber);
      setQuestions(response);
    }
    // const response = await getFetch(
    //   `question/paginate?page=${pageNumber}&size=${size}`
    // );
    // if (response.length > 0) {
    //   console.log("response =", response);
    //   setQuestions(response);
    //   setPage(pageNumber);
    // } else {
    //   // console.log("error =", response);
    //   alert("This is last page.");
    // }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div
          className="createTestSeries_singleSectionDiv"
          style={{
            zIndex: 10,
            position: "fixed",
            // position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backdropFilter: "blur(5px)",
            background: "rgba(0,0,0,0.4)",
          }}
        >
          <div
            style={{
              padding: "20px",
              borderRadius: "5px",
              background: "white",
              width: "96vw",
              // minWidth: "900px",
              maxHeight: "calc(100vh - 120px)",
              position: "relative",
              top: "30px",
              // overflowX: "auto",
            }}
          >
            {/* <span>{sectionIndex + 1}.</span> */}
            <div
              style={{
                display: "flex",
                // flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                // maxHeight: "90%",
                gap: "10px",
                // overflow: "auto",
                height: "calc(100vh - 120px - 60px - 40px)",
                // overflow: "auto",
              }}
            >
              <div
                style={{
                  // display: "flex",
                  // flexDirection: "column",
                  border: "1px solid #dddddd",
                  padding: "10px",
                  borderRadius: "5px",
                  width: "49%",
                  height: "100%",
                  overflowX: "auto",
                }}
              >
                <p style={{ fontSize: "1.2rem", fontWeight: "600" }}>
                  Question Bank
                </p>
                <table
                  style={{
                    width: "100%",
                    margin: "10px 0",
                    minWidth: "300px",
                    border: "1px solid #dddddd",
                    borderRadius: "5px",
                    padding: "5px",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          width: "30px",
                          // width: "48px",
                          borderBottom: "1px solid #dddddd",
                        }}
                      >
                        {/* Select */}
                      </th>
                      {/* <th
                        style={{
                          width: "35px",
                          borderBottom: "1px solid #dddddd",
                        }}
                      >
                        Sr
                      </th> */}
                      <th style={{ borderBottom: "1px solid #dddddd" }}>
                        Question
                      </th>
                      <th
                        style={{
                          width: "70px",
                          borderBottom: "1px solid #dddddd",
                        }}
                      >
                        Answer
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {questions?.map((questionEl, questionEli) => {
                      return (
                        <tr key={questionEli}>
                          <td
                            style={{
                              width: "35px",
                              borderBottom: "1px solid #dddddd",
                              textAlign: "center",
                            }}
                          >
                            <input
                              type="checkbox"
                              name={questionEl._id}
                              id={questionEl._id}
                              checked={selectedQuestions.some(
                                (selectedObj) =>
                                  selectedObj._id === questionEl._id
                              )}
                              onChange={() => {
                                const isSelected = selectedQuestions.some(
                                  (selectedObj) =>
                                    selectedObj._id === questionEl._id
                                );
                                if (isSelected) {
                                  setSelectedQuestions(
                                    selectedQuestions.filter(
                                      (selectedObj) =>
                                        selectedObj._id !== questionEl._id
                                    )
                                  );
                                } else {
                                  if (
                                    selectedQuestions.length >=
                                    maximumAllowedQuestions
                                  ) {
                                    alert("Maximum allowed questions reached.");
                                  } else {
                                    setSelectedQuestions([
                                      ...selectedQuestions,
                                      questionEl,
                                    ]);
                                  }
                                }
                              }}
                            />
                          </td>
                          {/* <td
                            style={{
                              width: "35px",
                              borderBottom: "1px solid #dddddd",
                              textAlign: "center",
                            }}
                          >
                            {(page - 1) * size + 1 + questionEli}
                          </td> */}
                          <td
                            style={{
                              padding: "0 5px",
                              borderBottom: "1px solid #dddddd",
                            }}
                          >
                            {/* {questionEl.question} */}
                            {questionEl.questionType === "TEXT" ? (
                              `${questionEl.question}`
                            ) : (
                              <div
                                className="showRichText"
                                dangerouslySetInnerHTML={{
                                  __html: questionEl.question,
                                }}
                              />
                            )}
                          </td>
                          <td
                            style={{
                              width: "70px",
                              borderBottom: "1px solid #dddddd",
                              textAlign: "center",
                            }}
                          >
                            {questionEl.answer.join(",")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {/* <button></button> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "600",
                    padding: "0 10px",
                  }}
                >
                  <span
                    onClick={() => handleChangePage(page - 1)}
                    style={{ cursor: "pointer" }}
                  >
                    Previous Page
                  </span>
                  {/* <span
                    onClick={() => console.log("selected =", selectedQuestions)}
                    style={{ cursor: "pointer" }}
                  >
                    Add To Test
                  </span> */}
                  <span
                    onClick={() => handleChangePage(page + 1)}
                    style={{ cursor: "pointer" }}
                  >
                    Next Page
                  </span>
                </div>
              </div>
              <div
                style={{
                  // display: "flex",
                  // flexDirection: "column",
                  border: "1px solid #dddddd",
                  padding: "10px",
                  borderRadius: "5px",
                  width: "49%",
                  // maxHeight: "80%",
                  height: "100%",
                  overflowX: "auto",
                }}
              >
                <p style={{ fontSize: "1.2rem", fontWeight: "600" }}>
                  Assigned Questions ( Max Allowed = {maximumAllowedQuestions} )
                </p>
                <table
                  style={{
                    width: "100%",
                    margin: "10px 0",
                    minWidth: "300px",
                    border: "1px solid #dddddd",
                    borderRadius: "5px",
                    padding: "5px",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          width: "30px",
                          // width: "48px",
                          borderBottom: "1px solid #dddddd",
                        }}
                      >
                        {/* Select */}
                      </th>
                      {/* <th
                        style={{
                          width: "35px",
                          borderBottom: "1px solid #dddddd",
                        }}
                      >
                        Sr
                      </th> */}
                      <th style={{ borderBottom: "1px solid #dddddd" }}>
                        Question
                      </th>
                      <th
                        style={{
                          width: "70px",
                          borderBottom: "1px solid #dddddd",
                        }}
                      >
                        Answer
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedQuestions?.map((questionEl, questionEli) => {
                      return (
                        <tr key={questionEli}>
                          <td
                            style={{
                              width: "35px",
                              borderBottom: "1px solid #dddddd",
                              textAlign: "center",
                            }}
                          >
                            <input
                              type="checkbox"
                              name={questionEl._id}
                              id={questionEl._id}
                              checked={updateSelectedQuestions.some(
                                (selectedObj) =>
                                  selectedObj._id === questionEl._id
                              )}
                              onChange={() => {
                                const isSelected = updateSelectedQuestions.some(
                                  (selectedObj) =>
                                    selectedObj._id === questionEl._id
                                );
                                if (isSelected) {
                                  setUpdateSelectedQuestions(
                                    updateSelectedQuestions.filter(
                                      (selectedObj) =>
                                        selectedObj._id !== questionEl._id
                                    )
                                  );
                                } else {
                                  setUpdateSelectedQuestions([
                                    ...updateSelectedQuestions,
                                    questionEl,
                                  ]);
                                }
                              }}
                            />
                          </td>
                          {/* <td
                            style={{
                              width: "35px",
                              borderBottom: "1px solid #dddddd",
                              textAlign: "center",
                            }}
                          >
                            {(page - 1) * size + 1 + questionEli}
                          </td> */}
                          <td
                            style={{
                              padding: "0 5px",
                              borderBottom: "1px solid #dddddd",
                            }}
                          >
                            {/* {questionEl.question} */}
                            {questionEl.questionType === "TEXT" ? (
                              `${questionEl.question}`
                            ) : (
                              <div
                                className="showRichText"
                                dangerouslySetInnerHTML={{
                                  __html: questionEl.question,
                                }}
                              />
                            )}
                          </td>
                          <td
                            style={{
                              width: "70px",
                              borderBottom: "1px solid #dddddd",
                              textAlign: "center",
                            }}
                          >
                            {questionEl.answer.join(",")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {/* <button></button> */}
                {updateSelectedQuestions.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontWeight: "600",
                      padding: "0 10px",
                    }}
                  >
                    <span
                      onClick={() => {
                        const removed = selectedQuestions.filter((obj1) => {
                          return !updateSelectedQuestions.find(
                            (obj2) => obj2._id === obj1._id
                          );
                        });
                        // console.log("updated =", updateSelectedQuestions);
                        // console.log("selected =", selectedQuestions);
                        console.log("removed =", removed);
                        setSelectedQuestions(removed);
                        setUpdateSelectedQuestions([]);
                        // setMaximumAllowedQuestions(undefined);
                        // setGroupIndex(undefined);
                      }}
                      style={{ cursor: "pointer", color: "red" }}
                    >
                      Remove Questions
                    </span>
                    {/* <span
                    onClick={() => console.log("selected =", selectedQuestions)}
                    style={{ cursor: "pointer" }}
                  >
                    see selected.
                  </span> */}
                    {/* <span
                    onClick={() => handleChangePage(page + 1)}
                    style={{ cursor: "pointer" }}
                  >
                    Next Page
                  </span> */}
                  </div>
                )}
              </div>
              {/* <span
                onClick={() => console.log("selected =", selectedQuestions)}
              >
                show selected
              </span> */}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "15px",
              }}
            >
              <button
                className="primaryBtn"
                onClick={async () => {
                  const updatedGroups = test.groups;
                  // const selectedQuestionsId = updateSelectedQuestions.map(
                  const selectedQuestionsId = selectedQuestions.map(
                    (el, index) => {
                      return el._id;
                    }
                  );
                  // console.log("group index =", groupIndex);
                  // console.log("selected questions =", selectedQuestions);
                  // console.log("updated questions =", updateSelectedQuestions);
                  // console.log("selectedQuestionsId =", selectedQuestionsId);
                  updatedGroups[groupIndex] = {
                    ...updatedGroups[groupIndex],
                    questions: selectedQuestionsId,
                  };
                  console.log("updated Groups =", updatedGroups);
                  const response = await updateFetch(
                    `test/dynamic/${test._id}`,
                    {
                      groups: updatedGroups,
                    },
                    instituteId
                  );

                  console.log("response =", response);
                  if (response) {
                    // alert("response arrived.");
                    window.location.reload();
                  } else {
                    alert("Can't Add Part.");
                  }
                }}
              >
                Update
              </button>
              <button
                className="dangerBtn"
                onClick={() => setShowManageQuestionsPopup(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
