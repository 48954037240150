import React, { useEffect, useState } from "react";
import "./header.css";
import { useNavigate } from "react-router-dom";
// import { useNavigate, useLocation } from "react-router-dom";
// import { Icon } from "@iconify/react";
// import { navMainUl } from "../../../constants/header";
import FullWidthHeader from "./fullWidthHeader/FullWidthHeader";
import MobileWidthHeader from "./mobileWidthHeader/MobileWidthHeader";
import { useSelector } from "react-redux";

const Header = () => {
  const loggedInUser = useSelector((state) => state.loggedUser.value);
  // const [setshowDropDown, setSetshowDropDown] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();
  // const location = useLocation();
  // console.log("logged user =", loggedUser);

  const handleNavigate = (path) => {
    navigate(path);
  };

  // useeffect for isMobile
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile]);

  if (!isMobile) {
    return (
      <FullWidthHeader
        handleNavigate={handleNavigate}
        userName={loggedInUser}
      />
    );
  }
  return (
    <MobileWidthHeader
      handleNavigate={handleNavigate}
      userName={loggedInUser}
    />
  );
};

export default Header;
