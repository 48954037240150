import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { navMainUl } from "../../../../constants/header";
import "./fullWidthHeader.css";

const FullWidthHeader = ({ handleNavigate, userName }) => {
  const [showDropDown, setshowDropDown] = useState("");
  const renderNavSingleComponents = (element) => {
    return (
      <div
        className={`${
          showDropDown === element.name &&
          element.childDropdown?.length > 0 &&
          "navSingleElementNameDivActive"
        } navSingleElementNameDiv`}
        onMouseEnter={() => setshowDropDown(element.name)}
        // onMouseLeave={() => setshowDropDown("")}
        onClick={() => {
          if (element.link) {
            handleNavigate(element.link);
          }
        }}
      >
        <span>
          {element.name}

          {element.childDropdown?.length > 0 && (
            <Icon
              className={`${
                showDropDown === element.name &&
                element.childDropdown?.length > 0
                  ? "navSingleElementIconActive"
                  : "navSingleElementIcon"
              }`}
              icon={"eva:arrow-left-fill"}
            />
          )}
        </span>
      </div>
    );
  };
  const renderShowDropDown = (element) => {
    return (
      <div className="dropDown">
        {element.map((el, eli) => {
          return (
            <div
              key={eli}
              className="singleDwopDownName"
              onClick={() => {
                if (el.link) {
                  handleNavigate(el.link);
                }
              }}
            >
              {el.name}
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className="headerMaindiv">
      <div className="topLogo">निदर्शन</div>
      {/* <div className="topLogo">Nidarshan</div> */}
      <div className="navBarMainDiv" style={{ textTransform: "capitalize" }}>
        {navMainUl.map((el, eli) => {
          return (
            <div
              key={eli}
              className="singleNavElementMainDiv"
              onMouseLeave={() => setshowDropDown("")}
              style={{ width: `${el.nameWidth}px` }}
            >
              {renderNavSingleComponents(el)}
              {showDropDown === el.name && el.childDropdown?.length > 0 && (
                <>{renderShowDropDown(el.childDropdown)}</>
              )}
            </div>
          );
        })}
        <div className="singleSignInElementMainDiv">
          {renderNavSingleComponents({
            name: `${userName ? userName.name : "Sign In"}`,
            link: "login",
          })}
          {/* {renderNavSingleComponents({ name: "Sign In", link: "login" })} */}
        </div>
      </div>
    </div>
  );
};

export default FullWidthHeader;
