import React, { useState } from "react";
// import axios from "axios";
import "./login.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import { postFetch } from "../../functions/fetch/fetch.crud";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";

const Login = () => {
  // const [loginClicked, setLoginClicked] = useState(false);
  const [loginDetails, setLoginDetails] = useState({
    // email: "sankalp@gmail.com",
    // password: "sank",
    email: "u1@gmail.com",
    password: "user1",
    // email: "harsh@gmail.com",
    // password: "harsh",
    // email: "",
    // password: "",
  });
  // const navigate = useNavigate();

  async function handleUpdateLoginDetails(e) {
    // e.preventDefault();
    const property = e.target.name;
    const value = e.target.value;
    setLoginDetails((prevValue) => {
      return { ...prevValue, [property]: value };
    });
  }

  async function handleLogin() {
    try {
      console.log("request loginDetails =", loginDetails);

      const response = await postFetch("user/login", loginDetails, null, true);

      if (response) {
        // toast.info(response);
        if (response.error) {
          toast.error("Error : " + response.message);
          return;
        }
        console.log("response =", response);
        if (response.success) {
          const { token, user } = response;
          localStorage.setItem("token", token);
          localStorage.setItem("user", JSON.stringify(user));
          window.location.href = "/";
        }
      } else {
        toast.error("Can't login now.");
      }
    } catch (error) {
      toast.error(JSON.stringify(error));
      console.log("Error Logging in.", error);
    }
  }

  return (
    <div className="login_main_parent_div">
      <div className="login_main_div">
        <div className="login_header">
          <h2 className="login-top-h1">Hello !</h2>
          <span>Welcome To Nidarshan</span>
        </div>
        <div className="input_form_main_div">
          <div className="user_name_div">
            <input
              type="text"
              name="email"
              placeholder="Email"
              value={loginDetails.email}
              onChange={handleUpdateLoginDetails}
            />
            <Icon className="login_fields_icon" icon={"mdi:user"} />
          </div>
          <div className="user_password_div">
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={loginDetails.password}
              onChange={handleUpdateLoginDetails}
            />
            <Icon className="login_fields_icon" icon={"ic:round-lock"} />
          </div>
          <div className="forgot_password_div">
            <span>Forget Password</span>
          </div>
          <div className="submit_button">
            <button className="login_button" onClick={handleLogin}>
              <span>Login</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
